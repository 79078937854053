import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiService } from '../services/api.service';
import { Router } from '@angular/router';
import MyContstant from "../../config/my-constant";
import { map } from 'rxjs/operators';

@Injectable()
export class AuthenticationService {

    private hitUrl: string;
    result: any;

    constructor(
        private http: HttpClient,
        private apiService: ApiService,
        private router: Router
    ) { }

    /**    
     * @description : this method calls http://localhost:3000/client/1.0/user/otp/get if success then return error otherwise send error.
     * @param : loginId 
     * @returns : user data in json format
     * @author : Roli Jain <roli.jain@icorprated.com>
     */

    getOTP(loginId: any) {
        this.hitUrl = MyContstant.baseUrl;
        //console.log('Function calling getOTP : ' + loginId);

        if(isNaN(loginId)){
            //console.log("email");
        return this.http.post<any>(this.hitUrl + `user/otp/get`, { email: loginId })
            .pipe(map(user => {
                return user;
            }));
        }else {
            //console.log("mobile");
            return this.http.post<any>(this.hitUrl + `user/otp/getPhone`, { phone: loginId })
            .pipe(map(user => {
                return user;
            }));
        }
    }



    /**    
    * @description : this method calls http://localhost:3000/client/1.0/user/authenticate if success then return error otherwise send error.
    * @param : otp, email 
    * @returns : user data in json format
    * @author : Roli Jain <roli.jain@icorprated.com>
    */

    login(otp: string, email: string) {
        this.hitUrl = MyContstant.baseUrl;
        //console.log('authenticate calling');
        return this.http.post<any>(this.hitUrl + `user/authenticate`, { email: email, otp: otp })
        .pipe(map(user => {
            return user;
        }));

    }
}