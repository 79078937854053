import { Injectable } from '@angular/core';
import { HttpClient, HttpClientModule, HttpHeaders } from '@angular/common/http';
import { ApiService } from '../services/api.service';
import MyConstant from "../../config/my-constant";
import { map } from 'rxjs/operators';

@Injectable()
export class NotificationService {
    private currentUser: any;
    private hitUrl: string;
    result: any;
    constructor(
        private http: HttpClient,
        private apiService: ApiService,
    ) {
        this.hitUrl = MyConstant.baseUrl;
    }

    private caseNumber: string = '';
    private queryId: string = '';

    saveCaseNumber(caseNumber) {
        this.caseNumber = caseNumber;
    }

    fetchCaseNumber() {
        return this.caseNumber;
    }

    saveQueryId(queryId) {
        this.queryId = queryId;
    }

    fetchQueryId() {
        return this.queryId;
    }

    /**
   * @description : This method calls notification api.
   * @param : account_id
   * @returns : Notification detail in json format
   * @author : Priyanka Pathak
   */

    getNotification(account_id: string) {

        this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
        const options = {
            headers: new HttpHeaders({
                "Content-Type": MyConstant.contentType,
                Authorization: this.currentUser.user.token
            })
        };
        return this.http.post<any>(this.hitUrl + `get/notification`, { account_id: account_id })
            .pipe(map(data => {
                return data;
            }));
    }
    /**
   * @description : This method calls notification api to archive the notification.
   * @param : notification_id
   * @returns : 
   * @author : Priyanka Pathak
   */

    archivedNotification(notification_id: string) {
        this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
        const options = {
            headers: new HttpHeaders({
                "Content-Type": MyConstant.contentType,
                Authorization: this.currentUser.user.token
            })
        };
        return this.http.post<any>(this.hitUrl + `archived/notification`, { notification_id: notification_id })
            .pipe(map(data => {
                //console.log("noti service " + data);
                return data;
            }));
    }
    /**
   * @description : This method calls notification api to read the notification.
   * @param : notification_id
   * @returns : 
   * @author : Priyanka Pathak
   */

    readNotification(notification_id: string) {
        this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
        const options = {
            headers: new HttpHeaders({
                "Content-Type": MyConstant.contentType,
                Authorization: this.currentUser.user.token
            })
        };
        return this.http.post<any>(this.hitUrl + `read/notification`, { notification_id: notification_id })
            .pipe(map(data => {
                //console.log("noti service " + data);
                return data;
            }));
    }


}