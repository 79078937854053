import { Component, OnInit, ElementRef, ViewChild } from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl
} from "@angular/forms";

import { AlertService } from "../services/alert.service";
import { AuthenticationService } from "../services/authentication.service";
import { Router } from "@angular/router";
import { first } from "rxjs/operators";
import MyConstant from "../../config/my-constant";

import { GoogleAnalyticsService } from "../services/google-analytics.service";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"]
})
export class LoginComponent implements OnInit {
  @ViewChild("focusTrg") trgFocusEl: ElementRef;

  focusOnce = false;
  otpForm: FormGroup;
  loginForm: FormGroup;
  submitted = false;
  submitted2 = false;
  message: any;
  success: any;
  otp: string;
  clicked = false;
  serverError: string;
  otpError: string;
  loginEmail: string;
  OtpGenerated = false;
  interval = null;
  time: string;

  m = 0;
  s = 0;
  t = 0;
  generateOTPSuccessMsg: string;

  constructor(
    private formBuilder: FormBuilder,
    private authenticationService: AuthenticationService,
    private alertService: AlertService,
    private router: Router,
    private googleAnalyticsService: GoogleAnalyticsService
  ) {}

  ngOnInit() {
    if (localStorage.getItem("currentUser")) {
      // return to dashboard
      this.router.navigate(["/dashboard"]);
    }

    //otp form validation
    this.otpForm = this.formBuilder.group({
      loginId: ["", Validators.required]
    });

    //login form validation

    this.loginForm = this.formBuilder.group({
      firstNumber: ["", Validators.required],
      secondNumber: ["", Validators.required],
      thirdNumber: ["", Validators.required],
      fourthNumber: ["", Validators.required],
      loginEmail: []
    });
  }

  // convenience getter for easy access to form fields of otp form
  get f() {
    return this.otpForm.controls;
  }

  // convenience getter for easy access to form fields of login form
  get f2() {
    return this.loginForm.controls;
  }

  /**
   * @description : this method calls when generate OTP button click if form input is valid then call getOTP service, if api is called successfully then start timer othervise return validation error.
   *
   * @author : Roli Jain <roli.jain@icorprated.com>
   */
  onSubmit() {
    //disable generate button click event
    this.clicked = true;
    this.submitted = true;
    // stop here if form is invalid
    if (this.otpForm.invalid) {
      //enable generate button click event
      this.clicked = false;
      return;
    }

    this.authenticationService
      .getOTP(this.f.loginId.value)
      .pipe(first())
      .subscribe(
        res => {
          if (JSON.stringify(res.status)) {
            //console.log("Token : " + JSON.stringify(res.data.result.token));
            // this.otpForm.reset();

            //remove error if any
            this.serverError = "";
            this.generateOTPSuccessMsg = MyConstant.generateOTPSuccessMsg;
            //set email
            this.serverError = "";
            this.generateOTPSuccessMsg = MyConstant.generateOTPSuccessMsg;
            this.loginEmail = res.data.result.email;
            this.t = Number(res.data.result.token_expiry);

            if (this.interval != null) {
              clearInterval(this.interval);
            }

            this.interval = setInterval(() => {
              if (this.focusOnce == false) {
                //set focus on first input after otp generated
                this.trgFocusEl.nativeElement.focus();
                this.focusOnce = true;
              }

              this.t = this.t - 1;
              this.time = this.createTimer(this.t);
              if (this.m == 0 && this.s == 0) {
                this.clicked = false;
                this.OtpGenerated = false;
                window.location.reload();

                clearInterval(this.interval);
              }
            }, 1000);

            //show login button and enter opt input
            this.OtpGenerated = true;

            this.googleAnalyticsService.eventEmitter("otpGeneration", `loginId: ${this.f.loginId.value}`, "success", 1);
          }
        },
        err => {
          this.clicked = false;
          if (err.error.status == "error") {
            // return to login
            this.serverError = JSON.stringify(err.error.message)
              .split('"')
              .join("");

              this.googleAnalyticsService.eventEmitter("otpGeneration", `loginId - ${this.f.loginId.value}`, `error: ${err.error.message}`, 1);
          }
        }
      );
  }

  /**
   * @description : this method calls when login button click if form input is valid then call login service, if api is called successfully then store userdata in session and redirect to dashboard othervise return validation error.
   *
   * @author : Roli Jain <roli.jain@icorprated.com>
   */

  onLoginSubmit() {
    this.submitted2 = true;
    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }

    this.otp =
      this.f2.firstNumber.value +
      this.f2.secondNumber.value +
      this.f2.thirdNumber.value +
      this.f2.fourthNumber.value;
    this.authenticationService
      .login(this.otp, this.loginEmail)
      .pipe(first())
      .subscribe(
        res => {
          // console.log("res" + JSON.stringify(res));
          if (JSON.stringify(res.status)) {
            //console.log("Message : " + JSON.stringify(res.messege));
            // console.log("result : " + JSON.stringify(res.data));
            //Store in to Session
            localStorage.setItem("currentUser", JSON.stringify(res.data));
            // return to dashboard
            this.router.navigate(["/dashboard"]);

            this.googleAnalyticsService.eventEmitter("loginAttempt", `loginId: ${this.f.loginId.value}`, "success", 2);
          }
        },
        err => {
          //console.log("err" + err);

          if (err.error.status == "error") {
            this.otpError = JSON.stringify(err.error.message)
              .split('"')
              .join("");
          }

          this.googleAnalyticsService.eventEmitter("loginAttempt", `loginId - ${this.f.loginId.value}`, `error: ${err.error.message}`, 2);
        }
      );
  }

  /**
   * @description : this method accept number and return time in MM:SS formate.
   * @param : number
   * @returns : time in MM:SS formate
   * @author : Roli Jain <roli.jain@icorprated.com>
   */

  createTimer(d: any) {
    d = Number(d);
    this.m = Math.floor((d % 3600) / 60);
    this.s = Math.floor((d % 3600) % 60);
    const mDisplay =
      this.m > 0 ? (this.m > 9 ? this.m + ":" : "0" + this.m + ":") : "00:";
    const sDisplay = this.s > 0 ? (this.s > 9 ? this.s : "0" + this.s) : "00";
    return mDisplay + sDisplay;
  }
}
