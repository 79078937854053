
import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { ApiService } from '../services/api.service';

@Injectable()
export class AuthMiddleware implements CanActivate {

    result: any;
    currentUser: any;

    constructor(
        private apiService: ApiService,
        private router: Router
    ) { }

    /**    
     * @description : this method check token is valid and expiry of taken if it's valid then return 200 otherwise return to authenticated token
     * @params : token
     * @author : Roli Jain <roli.jain@icorprated.com>
     */
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {        
        // let result: any = this.apiService.verifyToken('user/verify/token', {}, this.cb, this.router);
        if (this.apiService.verifyToken('user/verify/token', {}) && localStorage.getItem('currentUser')) {
            // logged in so return true
            return true;
        }

        // Not logged in so redirect to login page with the return url
        this.router.navigate([''], { queryParams: { returnUrl: state.url } });
        return false;
    }
}

