import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import MyConstant from "../../config/my-constant";

import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'import-cases-checkbox-dialog',
  templateUrl: './import-cases-checkbox-dialog.html',
})
export class ImportCasesCheckboxDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<ImportCasesCheckboxDialogComponent>) { }

  onNoClick(): void {
    this.dialogRef.close();
  }

}


