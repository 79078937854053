import { Injectable } from '@angular/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import MyContstant from "../../config/my-constant";
import { map } from 'rxjs/operators';

@Injectable()
export class DashboardService {

    private hitUrl: string;


    constructor(private http: HttpClient) { 
        this.hitUrl = MyContstant.baseUrl;
    }

    /**
     * @description : this method calls "base_url+statitics/get" if success then return error otherwise send error.
     * @param :
     * @returns : dashboard count data in json format
     * @author : Nitesh Sethi
     */
    dashboard(account_id: string, status: string, to_date: string, from_date: string) { 
        return this.http.post<any>(this.hitUrl + `statitics/get`, { account_id: account_id, bgvstatus_c: status, to_date: to_date, from_date: from_date })
        .pipe(map(response => {           
            return response;
        }));
    }


    getCaseCountCaseGrid(account_id: string, defaultDate: string, to_date: string, from_date: string) { 
        return this.http.post<any>(this.hitUrl + `statitics/getCaseCountCaseGrid`, { account_id: account_id, defaultDate: defaultDate, to_date: to_date, from_date: from_date })
        .pipe(map(response => {           
            return response;
        }));
    }
    dashboard_case_aging(account_id: string, to_date: string, from_date: string) {        

        return this.http.post<any>(this.hitUrl + `case_aging/get`, { account_id: account_id, to_date: to_date, from_date: from_date })
        .pipe(map(response => {           
            return response;
        }));
    }

    tatCalculation(account_id: string, to_date: string, from_date: string) { 
        return this.http.post<any>(this.hitUrl + `tatpercentage/get`, { account_id: account_id, to_date: to_date, from_date: from_date })
        .pipe(map(response => {           
            return response;
        }));
    }
}