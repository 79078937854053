
import { Component, OnInit, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import MyConstant from "../../config/my-constant";
import * as XLSX from 'xlsx';
import { DomSanitizer } from '@angular/platform-browser';
import { CasesService } from '../services/cases.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { NgForm } from '@angular/forms';
import * as fileSaver from 'file-saver';
import { MasterDataService } from '../services/master-data.service';
import { first } from 'rxjs/operators';
import { MatDialog, MatDialogRef, MatTableDataSource } from '@angular/material';
import { ImportCasesDialogComponent } from '../import-cases/import-cases-dialog';
import { ImportCasesConfirmDialogComponent } from '../import-cases/import-cases-confirm-dialog';
import { ImportCasesCheckboxDialogComponent } from '../import-cases/import-cases-checkbox-dialog';
import { ImportCasesSuccessDialogComponent } from '../import-cases/import-cases-success-dialog';
import { DatePipe } from '@angular/common';
import { ExcelService } from "../services/excel.service";
declare const $: any;
// AOA : array of array
type AOA = any[][];

@Component({
  selector: 'app-import-cases',
  templateUrl: './import-cases.component.html',
  styleUrls: ['./import-cases.component.scss']
})
export class ImportCasesComponent implements OnInit {
  toggle = true;
  status = 'Enable';
  toggl = true;
  statu = 'Enable';
  buttonDisable: any;
  isData = false;
  StateIndia: any;
  BGVCheckID: any;
  CaseType: any;
  bgv_check: any;
  DistrictIndia: any;
  PincodeIndia: any;
  isDataSubmitted = true;
  datain: any;
  result: any;
  array3: any;
  abc: any;
  resr: any;
  invalid: any;
  validtable =[];
  validMoverows =[];
  invalidMoverows1 =[] 
  validMoverows1 =[]
  validMoverows2 =[];
  invalidtable1 =[];
  invalidtable2 =[];
  invalidtable3 =[];
  invaliddata =[];
  invaliddata1 =[];
  invaliddata2 =[];
  invaliddata3 =[];
  invaliddata4 =[];
  invaliddata5 =[];
  TableOneSelections: any;
  TabSelections: any;
  total: any;
  totalvalid: number;
  totainlvalid: number;
  invalidvalidpincode = [];
  validpincode =[];
  datavalid_pin: any;
  pushdata: any[];
  isSelected: any;
  paginator: any;
  updatevalid = [];
  checktType =[];
  selecte =[];
  constructor(
    private sanitizer: DomSanitizer,
    private casesService: CasesService,
    private formBuilder: FormBuilder,
    private http: HttpClient,
    private masterDataService: MasterDataService,
    private dialog: MatDialog,
    private element: ElementRef,
    private datePipe: DatePipe,
    private excelService: ExcelService,
  ) {
    this.masterDataService.getDropdown();
    this.masterDataService.getStates();
    this.masterDataService.getDistrict();
    this.masterDataService.getPincode();
  }

  private currentUser: any;
  private account_id: string;
  private fileToUpload: File = null;
  private hitUrl: string;
  private serverUrl: string;
  private uploadedFiles: Array<File>;
  private documnts: Array<File>;
  private CaseForm: FormGroup;
  private search: string = '';
  private documentZip: any;
  private CaseData: any[] = [];
  private localdta : any[] = [];
  private CaseSuccessData: any[] = [];
  private selected = [];
  private validdata = [];
  private Case_Type =[];
  private groupList = [];
  private statedata = [];
  private distdata =[];
  private validtable1 =[];
  private validtable2 =[];
  private validtable3 =[];
  private validtable4 =[];
  private selectcheckvalid =[];
  private isEmptyDrop = true;
  private isExcelDrop = true;
  private isDisabled = false;
  private isActive = false;
  private successMessage;
  private errorMessage;
  private updatevalue =[];
  buttonDisabled: boolean;
  private distr_check =[];

  ImportCasesDialogRef: MatDialogRef<ImportCasesDialogComponent>;
  ImportCasesConfirmDialogRef: MatDialogRef<ImportCasesConfirmDialogComponent>;
  ImportCasesCheckboxDialogRef: MatDialogRef<ImportCasesCheckboxDialogComponent>;
  ImportCasesSuccessDialogRef: MatDialogRef<ImportCasesSuccessDialogComponent>;

  /**
   * sheet.js
   */
  private origExcelData: AOA = [
    ['Data: 2018/10/26'],
    ['Data: 2018/10/26'],
    ['Data: 2018/10/26'],
  ];
  filteredCalls: [];

  private refExcelData: Array<any>;
  private excelFirstRow = [];
  private excelDataEncodeToJson;
  private rows;
  private columns;
  private excelTransformNum = [];
  readonly headerHeight = 50;
  readonly footerHeight = 50;
  readonly rowHeight = 50;
  private editing = {};
  /** Default 的 excel file-name 文字 */
  private sheetJsExcelName = 'null.xlsx';

  /* excel sheet.js */
  private sheetCellRange;
  private sheetMaxRow;
  private localwSheet;
  private localWorkBook;
  private sheetNameForTab: Array<string> = ['excel tab 1', 'excel tab 2'];
  private totalPage = this.sheetNameForTab.length;
  private selectDefault;
  private sheetBufferRender;
 


  inputExcelOnClick(evt) {
    const target: HTMLInputElement = evt.target;
    if (target.files.length === 0) {
      throw new Error('未上傳');
    }
    if (target.files.length > 1) {
      throw new Error('Cannot use multiple files');
    }
    this.sheetJsExcelName = evt.target.files.item(0).name;
    const reader: FileReader = new FileReader();
    this.readerExcel(reader);
    reader.readAsArrayBuffer(target.files[0]);
    this.sheetBufferRender = target.files[0];
    this.isEmptyDrop = false;
    this.isExcelDrop = true;
    this.fileToUpload = evt.target.files;
  }

  ngOnInit() {
    this.buttonDisable = true;
    /**
     * @testing success-case-dialog
     */
    // this.openDialog();
    /**
     * @testing success-case-dialog
     */

    //empty default date and days of dashboard
    this.casesService.saveDashboardToDate('');
    this.casesService.saveDashboardFromDate('');
    this.casesService.saveDashboardDate('');

    /** Get current user id */
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.account_id = this.currentUser.user.account_id;
    //Case form Validation
    this.CaseForm = this.formBuilder.group({
      search: [],
      documentZip: []
    });
  }

  // convenience getter for easy access to form fields of otp form
  get f() { return this.CaseForm.controls; }

  transform(value) {
    return (value >= 26 ? this.transform(((value / 26) >> 0) - 1) : '') + 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'[value % 26 >> 0];
  }

  getJsDateFromExcel(serialDate: any) {
    var date = new Date((serialDate - (25567 + 2)) * 86400 * 1000);
    var localTime = new Date(date.getTime() + (new Date()).getTimezoneOffset() * 60000);
    return localTime;
    //return new Date(Math.round((serialDate - 25569) * 86400 * 1000));
  }
  ExcelDateToJSDate(serial){
    var utc_days = Math.floor(serial - 25569);
    var utc_value = utc_days * 86400;
    var date_info = new Date(utc_value * 1000);
    var fractional_day = serial - Math.floor(serial) + 0.0000001;
    var total_seconds = Math.floor(86400 * fractional_day);
    var seconds = total_seconds % 60;
    total_seconds -= seconds;
    var hours = Math.floor(total_seconds / (60 * 60));
    var minutes = Math.floor(total_seconds / 60) % 60;
    return new Date(date_info.getFullYear(), date_info.getMonth(), date_info.getDate(), hours, minutes, seconds);
    //return new Date( hours, minutes, seconds);
  }
  
  readerExcel(reader, index = 0) {
  /* reset array */
  this.CaseData = [];
  this.origExcelData = [];
  reader.onload = (e: any) => {
    const data: string = e.target.result;
    const wBook: XLSX.WorkBook = XLSX.read(data, { type: 'array' });
    this.localWorkBook = wBook;
    const wsname: string = wBook.SheetNames[index];
    this.sheetNameForTab = wBook.SheetNames;
    this.totalPage = this.sheetNameForTab.length;
    this.selectDefault = this.sheetNameForTab[index];
    const wSheet: XLSX.WorkSheet = wBook.Sheets[wsname];
    this.localwSheet = wSheet;
    this.sheetCellRange = XLSX.utils.decode_range(wSheet['!ref']);
    this.sheetMaxRow = this.sheetCellRange.e.r;
    this.origExcelData = <AOA>XLSX.utils.sheet_to_json(wSheet, {
      header: 1,
      range: wSheet['!ref'],
      raw: true,
    });
    this.refExcelData = this.origExcelData.slice(1).map(value => Object.assign([], value));
  /* 抓 range & 清除占存 A->Z */
  this.excelTransformNum = [];
  for (let idx = 0; idx <= this.sheetCellRange.e.c; idx++) {
    this.excelTransformNum[idx] = this.transform(idx);
  }
/* 加入 order 的佔位(#) */
  this.refExcelData.map(x => x.unshift('#'));
  this.excelTransformNum.unshift('order');
  let s_no = 1;
  let chid = '';
//console.log("Excel data "+  this.refExcelData);
  this.refExcelData.forEach(Result => {
    let c_data: any = {};
    // c_data.check_box = this.checkbox;
    //c_data.status = Result[1];
    chid += "'" + Result[4] + "',";
    let res1 = '';
    res1 = Result[1];
    let res2 = Result[2];
    let res3 = Result[3];
    let res4 = Result[4];
    let res5 = Result[5];
    let res6 = Result[6];
    let res7 = Result[7];
    let res8 = Result[8];
    let res9 = Result[9];
    let res10 = Result[10];
    let res11 = Result[11];
    //let res12 = Result[12];
    //let res13 = Result[13];
    let res14 = Result[14];
    //let res15 = Result[15];
    //c_data.bgvremark = Result[1] ? res1.toString().replace(/[^a-zA-Z0-9 ]/g, "") : Result[1];
    c_data.bgvremark = Result[1];
    c_data.type = Result[2] ? res2.toString().replace(/[^a-zA-Z0-9 ]/g, "") : Result[2];
    c_data.bgvclient_name = Result[3] ? res3.toString().replace(/[^a-zA-Z0-9 ]/g, "") : Result[3];
    c_data.bgvcheck_id = Result[4];
    c_data.bgvcandidate_name = Result[5] ? res5.toString().replace(/[^a-zA-Z ]/g, "") : Result[5];
    c_data.bgvfather = Result[6] ? res6.toString().replace(/[^a-zA-Z0-9 ]/g, "") : Result[6];
    c_data.bgvaddress = Result[7] ? res7.toString().replace(/[^a-zA-Z0-9 ]/g, "") : Result[7];
    c_data.district_india = Result[8] ? res8.toString().replace(/[^a-zA-Z0-9 ]/g, "") : Result[8];
    c_data.state_india = Result[9] ? res9.toString().replace(/[^a-zA-Z0-9 ]/g, "") : Result[9];
    c_data.bgv_pincode = Result[10] ? res10.toString().replace(/[^0-9 ]/g, "") : Result[10];
    //c_data.bgv_country = Result[12];
     c_data.bgv_mobile = Result[11] ? res11 : Result[11];
     if (Result[12] != undefined) {
       //console.log(Result[12]);
       let res12 = this.getJsDateFromExcel(Result[12]);
       let res122 = this.datePipe.transform(res12, "yyyy-MM-dd", "Asia/Calcutta");
       //console.log("Date 1" + res122);
       c_data.appointment_date = res122;
      } 
      // else {
      //   c_data.appointment_date = "";
      // }
      if (Result[13] != undefined) {
        //console.log("Time " + Result[13]);
        c_data.appointment_time = this.ExcelDateToJSDate(Result[13]);
        c_data.appointment_time = this.datePipe.transform(c_data.appointment_time, "h:mm:ss a", "Asia/Calcutta");
      }
      c_data.case_remark = Result[14] ? res14.toString().replace(/[^a-zA-Z0-9 ]/g, "") : Result[14];
      c_data.document_url = Result[15];
      if (JSON.stringify(c_data) != '{}') {
        this.CaseData.push(c_data);
      }
      s_no++;
    });
    chid = chid.replace(/,\s*$/, "");
    this.masterDataService.checkBGVId(chid);
    this.rows = this.CaseData;
    this.total =  this.rows.length;
    this.CaseData.forEach(res => {
    this.validdata.push(res.bgvcheck_id);
  });
  this.onPushCaseSubmit();
  this.isData = true;
  this.isDisabled = true;
  this.isActive = true;
};
}
fileChange(evt) {
  const target: HTMLInputElement = evt.target;
  if (target.files.length === 0) {
    throw new Error('未上傳');
  }
  if (target.files.length > 1) {
    throw new Error('Cannot use multiple files');
  }
  this.sheetJsExcelName = evt.target.files.item(0).name;
  const reader: FileReader = new FileReader();
  this.readerExcel(reader);
  reader.readAsArrayBuffer(target.files[0]);
  this.sheetBufferRender = target.files[0];
  this.isEmptyDrop = false;
  this.isExcelDrop = true;
  this.uploadedFiles = evt.target.files;
}
documentFileChange(evt) {
  const target: HTMLInputElement = evt.target;
  if (target.files.length === 0) {
    throw new Error('未上傳');
  }
  if (target.files.length > 1) {
    throw new Error('Cannot use multiple files');
  }
  this.documnts = evt.target.files;
}
validatePincode({ row, column, value }): any {
  this.PincodeIndia = JSON.parse(localStorage.getItem('pincodeData'));
  let VAL = '';
  this.PincodeIndia.forEach(CustomPincode => {
    if (value == CustomPincode.name) {
      VAL = CustomPincode.name;
    }
  });
  if (!VAL){
    this.isDataSubmitted = false;
    return " my-custom-cell ";
  }
}
/**
  * @description : this method will populate name of key from stateData.
  * @param : any
  * @returns : Name
  * @author : Pankaj Gupta
  */
 validateState({ row, column, value }): any {
  this.StateIndia = JSON.parse(localStorage.getItem('stateData'));
  let VAL = '';
  this.StateIndia.forEach(CustomState => {
    if (value.trim() === CustomState.name.trim()) {
      VAL = CustomState.name;
    }
  });
  if (!VAL) {
    this.isDataSubmitted = false;
    return " my-custom-cell ";
  }
}
/**
  * @description : this method will populate name of key from District.
  * @param : any
  * @returns : Name
  * @author : Pankaj gupta
  */

validateDistrict({ row, column, value }): any {
  this.DistrictIndia = JSON.parse(localStorage.getItem('districtData'));
  let VAL = '';
  this.DistrictIndia.forEach(CustomDistrict => {
    if (value.trim() === CustomDistrict.name.trim()) {
      VAL = CustomDistrict.name;
    }
  });
  if (!VAL) {
    this.isDataSubmitted = false;
    return " my-custom-cell ";
  }
}
/**
  * @description : this method will populate name of key from CaseType.
  * @param : any
  * @returns : Name
  * @author : Pankaj Gupta
  */
validateCaseType({ row, column, value }): any {
  this.CaseType = JSON.parse(localStorage.getItem('type'));
  let VAL = '';
  this.CaseType.forEach(CustomType => {
    if (value.trim() === CustomType.value.trim()) {
      VAL = CustomType.value;
    }
  }
  );
  if (!VAL) {
    return " my-custom-cell ";
  }
}
/**
  * @description : this method will be invalide table update method.
  * @param : any
  * @returns : Name
  * @author : Pankaj Gupta
  */
inupdateValue(event, cell, rowIndex) {
  if (event.target.value != ""){
    this.editing[rowIndex + '-' + cell] = false;
    if (cell == 'bgvcheck_id' || cell == 'appointment_date' || cell == 'appointment_time' || cell == 'document_url') {
      this.invaliddata[rowIndex][cell] = event.target.value;
    }
    else if (cell == 'bgvcandidate_name') {
      this.invaliddata[rowIndex][cell] = event.target.value.replace(/[^a-zA-Z ]/g, "");
    }
    else {
      this.invaliddata[rowIndex][cell] = event.target.value.replace(/[^a-zA-Z0-9 ]/g, "");
    }
    this.invaliddata = [...this.invaliddata];
    this.updatevalue.push( this.invaliddata[rowIndex][cell]);
  }
  else{
    this.editing[rowIndex + '-' + cell] = true;
    this.invaliddata[rowIndex][cell] = event.target.value;
    this.invaliddata = [...this.invaliddata]; 
  } 
}
// onSelect({ selected, index }) { 
//   if (selected.length > 0) {this.buttonDisabled = false;}
//   else { this.buttonDisabled = true; }
//   if(index != -1) {
//     this.selected.splice(0, this.selected.length);
//   this.selected.push(...selected);
//   }
// }

onSelect({selected}) {   
  if (selected != undefined) {
    this.selected.splice(0, this.selected.length);
    this.selected.push(...selected);
  }
//  console.log(JSON.parse(localStorage.getItem('selectedrows')));

}

// chack update value and local storege 
// moveup(){ 
//   // check update value with local DistrictIndia
//   this.DistrictIndia = JSON.parse(localStorage.getItem('districtData'));
//   this.DistrictIndia.forEach(dist=>{
//     this.distdata.push(dist.name)
//   })
//   let matches = [];
//   for ( let i = 0; i < this.updatevalue.length; i++ ) {
//     for ( let e = 0; e < this.distdata.length; e++ ) {
//       if ( this.updatevalue[i] == this.distdata[e] ) matches.push( this.updatevalue[i] );
//     }
//   }
//   matches.forEach(ite => {
//   this.selected.forEach(pin => {
//   if (ite == pin.district_india) {
//   this.selectcheckvalid.push(pin);
// }
// })
// })
// // check update value with local StateIndia
// this.StateIndia = JSON.parse(localStorage.getItem('stateData'));
// this.StateIndia.forEach(state=>{
//   this.statedata.push(state.name)
// })
// let matches1 = [];
// for ( let i = 0; i < this.updatevalue.length; i++ ) {
//   for ( let e = 0; e < this.statedata.length; e++ ) {
//     if ( this.updatevalue[i] == this.statedata[e] ) matches1.push( this.updatevalue[i] );
//   }
// }
// matches1.forEach(ite => {
//   this.selected.forEach(pin => {
//     if (ite == pin.state_india) {
//       this.selectcheckvalid.push(pin);
//     }
//   })
// })
// // check update value with local CaseType
// this.CaseType = JSON.parse(localStorage.getItem('type'));
// this.CaseType.forEach(type => {
//   this.Case_Type.push(type.name)
// });
// let matches5 = [];
// for ( let i = 0; i < this.updatevalue.length; i++ ) {
//   for ( let e = 0; e < this.Case_Type.length; e++ ) {
//     if ( this.updatevalue[i] == this.Case_Type[e] ) matches5.push( this.updatevalue[i] );
//   }
// }
// matches5.forEach(ite => {
//   this.selected.forEach(pin => {
//     if (ite == pin.type) {
//       this.selectcheckvalid.push(pin);
//     }
//   })
// })
// // check update value with local bgvcheckidData
// this.localdta =  JSON.parse(localStorage.getItem('bgvcheckidData'));
// this.localdta.forEach(data => {
//   this.groupList.push(data.name)
// });
// let matches3 = [];
// for ( let i = 0; i < this.updatevalue.length; i++ ) {
//   for ( let e = 0; e < this.groupList.length; e++ ) {
//     if ( this.updatevalue[i] != this.groupList[e] ) matches3.push( this.updatevalue[i] );
//   }
// }
// this.updatevalue.forEach(ite => {
//   this.selected.forEach(pin => {
//     if (ite != pin.bgvcheck_id) {
//       this.selectcheckvalid.push(pin);
//     }
//   })
// })
// this.sendrows( this.selectcheckvalid);
// }

// check update value with click moveup function
  compareTypemoved(a, b) {
    return (a.type === b.value);
  }
  compareCheckidmoved(a, b) {
    return (a.bgvcheck_id === b.name);
  }
  compareDistricmoved(a, b) {
    return (a.district_india === b.name);
  }
  compareStatemoved(a, b) {
    return (a.state_india === b.name);
  }
  comparebgvpinmoved(a, b) {
    return (a.bgv_pincode === b.name);
  }
  moveup(){
  
    // check validated case Type 
    this.CaseType = JSON.parse(localStorage.getItem('type'));
    
    let temp = this.selected;
    this.validMoverows1 = temp.filter(a => {
      let indexFound = this.CaseType.findIndex(b => this.compareTypemoved(a, b));
      return indexFound != -1;
    })
    this.invalidMoverows1 = temp.filter(a => {
      let indexFound = this.CaseType.findIndex(b => this.compareTypemoved(a, b));
      return indexFound == -1;
    })
    //check id validation
    this.bgv_check = JSON.parse(localStorage.getItem('bgvcheckidData'));
    let temp1 = this.validMoverows1;
    this.validMoverows1 = temp1.filter(a => {
      let indexFound = this.bgv_check.findIndex(b => this.compareCheckidmoved(a, b));
      return indexFound == -1;
    })
    this.invalidMoverows1 = temp1.filter(a => {
      let indexFound = this.bgv_check.findIndex(b => this.compareCheckidmoved(a, b));
      return indexFound != -1;
    })
  // District India  validation
  this.DistrictIndia = JSON.parse(localStorage.getItem('districtData'));
  let temp2 = this.validMoverows1;
  this.validMoverows1 = temp2.filter(a => {
    let indexFound = this.DistrictIndia.findIndex(b => this.compareDistricmoved(a, b));
    return indexFound != -1;
  })
  this.invalidMoverows1 = temp2.filter(a => {
    let indexFound = this.DistrictIndia.findIndex(b => this.compareDistricmoved(a, b));
    return indexFound == -1;
  })
// StateIndia India  validation
this.StateIndia = JSON.parse(localStorage.getItem('stateData'));
let temp3 = this.validMoverows1;
this.validMoverows1 = temp3.filter(a => {
  let indexFound = this.StateIndia.findIndex(b => this.compareStatemoved(a, b));
  return indexFound != -1;
})
this.invalidMoverows1 = temp3.filter(a => {
  let indexFound = this.StateIndia.findIndex(b => this.compareStatemoved(a, b));
  return indexFound == -1;
})
  // StateIndia India  validation
  this.PincodeIndia = JSON.parse(localStorage.getItem('pincodeData'));
  let temp4 = this.validMoverows1;
  this.validMoverows1 = temp4.filter(a => {
    let indexFound = this.PincodeIndia.findIndex(b => this.comparebgvpinmoved(a, b));
    return indexFound != -1;
  })
  this.invalidMoverows1 = temp4.filter(a => {
    let indexFound = this.PincodeIndia.findIndex(b => this.comparebgvpinmoved(a, b));
    return indexFound == -1;
  })
  this.validMoverows1.forEach(item => {
  let i : number = this.validMoverows1.findIndex(d => d === item);  
  this.validtable.push(this.validMoverows1[i]); 
  let index : number = this.invaliddata.findIndex(d => d === item); 
  localStorage.setItem('selectedrows', JSON.stringify( this.invaliddata));
  this.selecte = JSON.parse(localStorage.getItem('selectedrows'));
  this.selected= this.selecte;

  if(index != -1) {
    this.invaliddata.splice(index, 1);
    this.selected.length = 0;
  }
});
      if (this.validMoverows1.length !=0) {
        // console.log("success");
      }else{
        const dialogRef = this.dialog.open(ImportCasesConfirmDialogComponent, {
          width: '350px',
          data: "Do you confirm the deletion of this data?"
        });
        return false;
      }
}
  /**
   * @description : this method will click uploade button push to data both table valid and invalid .
  * @param : any
  * @returns : table
  * @author : Pankaj Gupta
  */
  // import file validation
  compareType(i, j) {
    return (i.type === j.value);
  }
  compareCheckid(i, j) {
    return (i.bgvcheck_id === j.name);
  }
  compareDistric(i, j) {
    return (i.district_india === j.name);
  }
  compareState(i, j) {
    return (i.state_india === j.name);
  }
  comparebgvpin(i, j) {
    return (i.bgv_pincode === j.name);
  }
  onPushCaseSubmit(){
    // click button change color
    this.buttonDisable = false;
    this.toggle = !this.toggle;
    this.status = this.toggle ? 'Enable' : 'Disable';
    // check case type validated
    this.CaseType = JSON.parse(localStorage.getItem('type'));
    let tempRows = this.rows;
    this.validtable1 = tempRows.filter(i => {
      let indexFound = this.CaseType.findIndex(j => this.compareType(i, j));
      return indexFound != -1;
    })
    this.invaliddata1 = tempRows.filter(i => {
      let indexFound = this.CaseType.findIndex(j => this.compareType(i, j));
      return indexFound == -1;
    })
// check id validation
    this.bgv_check = JSON.parse(localStorage.getItem('bgvcheckidData'));
    let tempRows1 = this.validtable1;
    this.validtable2 = tempRows1.filter(i => {
      let indexFound = this.bgv_check.findIndex(j => this.compareCheckid(i, j));
      return indexFound == -1;
    })
    this.invaliddata2 = tempRows1.filter(i => {
      let indexFound = this.bgv_check.findIndex(j => this.compareCheckid(i, j));
      return indexFound != -1;
    })
    this.invalidtable1= this.invaliddata1.concat(this.invaliddata2);
// District India  validation
   this.DistrictIndia = JSON.parse(localStorage.getItem('districtData'));
   let tempRows2 = this.validtable2;
   this.validtable3 = tempRows2.filter(i => {
     let indexFound = this.DistrictIndia.findIndex(j => this.compareDistric(i, j));
     return indexFound != -1;
    })
    this.invaliddata3 = tempRows2.filter(i => {
      let indexFound = this.DistrictIndia.findIndex(j => this.compareDistric(i, j));
      return indexFound == -1;
    })
    this.invalidtable2= this.invalidtable1.concat(this.invaliddata3);
// StateIndia India  validation
    this.StateIndia = JSON.parse(localStorage.getItem('stateData'));
    let tempRows3 = this.validtable3;
    this.validtable4 = tempRows3.filter(i => {
      let indexFound = this.StateIndia.findIndex(j => this.compareState(i, j));
      return indexFound != -1;
    })
    this.invaliddata4 = tempRows3.filter(i => {
      let indexFound = this.StateIndia.findIndex(j => this.compareState(i, j));
      return indexFound == -1;
    })
    this.invalidtable3= this.invalidtable2.concat(this.invaliddata4);
// StateIndia India  validation
    this.PincodeIndia = JSON.parse(localStorage.getItem('pincodeData'));
    let tempRows4 = this.validtable4;
    this.validtable = tempRows4.filter(i => {
      let indexFound = this.PincodeIndia.findIndex(j => this.comparebgvpin(i, j));
      return indexFound != -1;
    })
    this.invaliddata5 = tempRows4.filter(i => {
      let indexFound = this.PincodeIndia.findIndex(j => this.comparebgvpin(i, j));
      return indexFound == -1;
    })
    this.invaliddata= this.invalidtable3.concat(this.invaliddata5);
    this.totalvalid = this.validtable.length;
    this.totainlvalid = this.total -this.totalvalid ;
  }

 /**
   * @description : this method will submit cases on CRM.
  * @param : any
  * @returns : success
  * @author : Pankaj Gupta
  */
pushCaseData(form: NgForm, validtable) {
  this.toggl = !this.toggl;
  this.statu = this.statu ? 'Enable' : 'Disable';
    let invalid = this.validtable;
     let _ii = 0;
    for (; _ii < invalid.length; _ii++) {
      try {
        const _outerHTML = invalid[_ii].outerHTML;
        const _el: any = document.createElement('div');
        _el.innerHTML = _outerHTML;
        const _ssts = _el.firstChild.getAttribute('ng-reflect-is-selected');
        if (_ssts == "true") {
          break;
        }
      } catch (e) {
        console.log("exception occured during invalidate element " + e);
      }
    }
    if (_ii == invalid.length) {
      if (this.validtable.length > 0) {
        //check if document zip is not uploaded 
        this.documentZip = this.f.documentZip.value;
        if (this.documentZip == null) {
          const dialogRef = this.dialog.open(ImportCasesDialogComponent, {
            width: '550px',
            data: "Do you confirm the deletion of this data?"
          });
          dialogRef.afterClosed().subscribe(result => {
            if (result) {
              //console.log('Yes clicked');
              // DO SOMETHING
              this.pushCaseCrm(this.validtable);
              //this.pushCaseData();
            }
          });
        } else {
          this.pushCaseCrm(this.validtable);
          //this.pushCaseData();
        }
      }
      else {
        const dialogRef = this.dialog.open(ImportCasesCheckboxDialogComponent, {
          width: '350px',
          data: "Do you confirm the deletion of this data?"
        });
        return false;
        alert("Please selected at least one check box then push cases on CRM");
      }
    } else {
      
      const dialogRef = this.dialog.open(ImportCasesConfirmDialogComponent, {
        width: '350px',
        data: "Do you confirm the deletion of this data?"
      });
      return false;
    }
}
 /**
   * @description : this method will submit selected cases on CRM.
  * @param : any
  * @returns : success
  * @author : Pankaj Gupta
  */
  pushCaseCrm(validtable) {
   //this.rows = [...this.rows];
   this.rows = this.validtable;
   this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
   let account_id = this.currentUser.user.account_id;
   let account_name = this.currentUser.user.account_name;

   this.hitUrl = MyConstant.baseUrl;
   let formData = new FormData();
   if (this.documentZip != null) {
     for (var i = 0; i < this.documnts.length; i++) {
       formData.append("documents[]", this.documnts[i], this.documnts[i].name);
     }
   }
   formData.append('cases', JSON.stringify(this.rows));
   formData.append('account_id', account_id);
   formData.append('account_name', account_name);

   const options = {
     headers: new HttpHeaders({
       "Content-Type": MyConstant.contentType,
       Authorization: this.currentUser.user.token
     })
   };

   this.http.post(this.hitUrl + `case/import`, formData)
     .pipe(first())
     .subscribe(
       res => {
       
         this.CaseSuccessData = [];
         res["data"].forEach(Result => { 
           let c_data: any = {};
  
           c_data.bgvcheck_id = Result.bgvcheck_id;
      
           if (Result.documents && Result.documents.length > 0) {
             c_data.doc_upload = true;  

           }
           else {
             c_data.doc_upload = false;
           }
           this.CaseSuccessData.push(c_data);
         });
         this.casesService.saveCaseSuccessData(this.CaseSuccessData);
         const dialogRef = this.dialog.open(ImportCasesSuccessDialogComponent, {
           panelClass: 'importDialogWrapper'
         });
       },
       err => {
        //  console.log("err" + JSON.stringify(err));
         this.errorMessage = err;
       }
     );
    
  }
    /**
  * @description : this method will check bgv checkid.
  * @param : any
  * @returns : Name
  * @author : Nitesh Sethi
  */
validateCheckID({ row, column, value }): any {
  value = value.toString();
  if (value == null || value == '') {
    this.isDataSubmitted = false;
    return " my-custom-cell ";
  } else {
    this.BGVCheckID = JSON.parse(localStorage.getItem('bgvcheckidData'));
    let VAL = '';
    this.BGVCheckID.forEach(CustomCheckid => {
      if (value.trim() === CustomCheckid.name.trim()) {
        VAL = CustomCheckid.name;
      }
    });
    if (VAL) {
      this.isDataSubmitted = false;
      return " my-custom-cell ";
    }
  }
}
/**
   * @description : this method will export valid and invalid data.
  * @param : any
  * @returns : excel
  * @author : Pankaj Gupta
  */
exportdata(){
  this.excelService.downloadFile(this.invaliddata, 'jsontocsv');
}
validexportdata(){
  this.excelService.downloadFile(this.validtable, 'jsontocsv');
}
DownloadFile() {
  this.serverUrl = MyConstant.serverUrl;
  let filename = this.serverUrl + "assets/SampleCaseFile.xlsx";
  fileSaver.saveAs(filename, "SampleCaseFile.xlsx");
}

}
