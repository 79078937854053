import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import MyConstant from "../../config/my-constant";
import { CasesService } from "../services/cases.service";

import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA
} from "@angular/material/dialog";

@Component({
  selector: "import-cases-success-dialog",
  templateUrl: "./import-cases-success-dialog.html"
})
export class ImportCasesSuccessDialogComponent {
  row: any[];
  CaseDataf: any[] = [];
  constructor(
    public dialogRef: MatDialogRef<ImportCasesSuccessDialogComponent>,
    private CasesService: CasesService
  ) {}

  public cases = this.CasesService.fetchCaseSuccessData();
  CaseData: any[] = [];
  rows: any[] = [];
  columns: any;
  checkdummy =[];
  checkstatus =[];
  //settings for ngx data table to fetch cases
  readonly headerHeight = 30;
  readonly rowHeight = 50;
  readonly footerHeight = 30;

  ngOnInit() {
    this.cases = this.CasesService.fetchCaseSuccessData();
    let s_no = 1;
    // console.log(" this.cases", this.cases);
    this.cases.forEach(item=>{
      this.checkdummy.push(item.bgvcheck_id)
      // console.log("this.checkdummy",this.checkdummy);
    })
      this.cases.forEach(item=>{
        this.checkstatus.push(item.doc_upload)
        // console.log("this.checkstatus",this.checkstatus);
        
      })
     
  /**
   * @description : this method will .
  * @param : any
  * @returns : 
  * @author : Pankaj Gupta
  */
    this.cases.forEach(Result => {
      // console.log("Result", Result);
      
      //console.log("res" + JSON.stringify(Result));
      let c_data: any = {};

      c_data.bgvcheck_id = Result.bgvcheck_id;
      c_data.doc_upload_status = Result.doc_upload;
       
      s_no++;
      if (c_data.doc_upload_status == true) {
      // console.log(true);
      this.CaseData.push(c_data);
      }else{
        // console.log(false);
        this.CaseDataf.push(c_data);
        
      }
    });
    
   
    
    this.rows = this.CaseData;
    this.columns = [
      { prop: "bgvcheck_id", name: "Check ID", width: 300 },
      { prop: "doc_upload_status", name: "Document Uploaded", width: 150 }
    ];
    this.row = this.CaseDataf;
    this.columns = [
      { prop: "bgvcheck_id", name: "Check ID", width: 300 },
      { prop: "doc_upload_status", name: "Document Uploaded", width: 150 }
    ];
  }
 

  close() {
    window.location.reload();
  }
}
