import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { CasesGridComponent } from './cases-grid/cases-grid.component';
import { AuthMiddleware } from './services/auth.middleware.service';
import { ImportCasesComponent } from './import-cases/import-cases.component';

const routes: Routes = [
  { path: '', component: LoginComponent },
  { path: 'login', component: LoginComponent },
  { path: 'dashboard', component: DashboardComponent, canActivate: [AuthMiddleware] },
  { path: 'cases', component: CasesGridComponent, canActivate: [AuthMiddleware] },
  { path: 'cases/:name', component: CasesGridComponent, canActivate: [AuthMiddleware] },
  { path: 'importcase', component: ImportCasesComponent, canActivate: [AuthMiddleware] },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
export const routingComponent = [
  LoginComponent,
  ForgotPasswordComponent,
  ResetPasswordComponent,
  DashboardComponent,
  CasesGridComponent,
  ImportCasesComponent
]
