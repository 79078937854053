import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-side-banner',
  templateUrl: './side-banner.component.html',
  styleUrls: ['./side-banner.component.scss']
})

export class SideBannerComponent implements OnInit {
  constructor(private router : Router) { }
  ngOnInit() {
  }

}
