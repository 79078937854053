import { Injectable, Output, EventEmitter } from '@angular/core';
import MyContstant from "../config/my-constant";

@Injectable()
export class SideBarService {

    sidebarOpen: any = MyContstant.sidebar_collapsed;
    @Output() change: EventEmitter<boolean> = new EventEmitter();

    toggle() {
        this.sidebarOpen = !this.sidebarOpen;
        this.change.emit(this.sidebarOpen);
    }

    reset(){
        MyContstant.sidebar_collapsed = true;
        this.sidebarOpen = MyContstant.sidebar_collapsed;
    }

}