import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { HttpHeaders } from "@angular/common/http";
import { Router } from "@angular/router";
import MyContstant from "../../config/my-constant";


@Injectable()
export class ApiService {
  private hitUrl: string;
  private currentUser: any;

  constructor(
    private router: Router,
    private http: HttpClient,

  ) { }

  postDataToService(Url: string, body: any) {
    let response: any;
    this.hitUrl = MyContstant.baseUrl + Url;
    //console.log("post: " + this.hitUrl);
    const options = {
      headers: new HttpHeaders({
        "Content-Type": MyContstant.contentType,
        Authorization: this.currentUser.user.token
      })
    };
    try {
      response = this.http.post(this.hitUrl, body, options);
    } catch (e) {
      console.log("Exception ApiService postDataToService " + e);
      return e;
    }

    //console.log("Token validation response: " + JSON.stringify(response));
    return response;



  }

  // verifyToken(Url: string, body: any, cb: any, router: any) {
  verifyToken(Url: string, body: any) {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.hitUrl = MyContstant.baseUrl + Url;
    //console.log("post: " + this.hitUrl);
    const options = {
      headers: new HttpHeaders({
        "Content-Type": MyContstant.contentType,
        Authorization: this.currentUser.user.token
      })
    };
    
    return this.http.post(this.hitUrl, body, options)
      .toPromise()
      .then((result: any) => {
        // update currentuser in localstorage
        localStorage.setItem("currentUser", JSON.stringify(result.data));
        return true;
      })
      .catch((err: any) => {
        //alert(JSON.stringify(err.error.message).split('"').join(""));
        // remove user from session storage to log out
        localStorage.removeItem('currentUser');
        this.router.navigate(['login']);
        return false;
      });
  }
}
