export default class MyConstant {
    public static sidebar_collapsed: boolean = true;
    // public static baseUrl: string = "https://stageapi.anaxee.com:3443/client/1.0/";
    public static baseUrl: string = "https://api.anaxee.com:3443/client/1.0/";
    public static contentType: string = "application/json";
    public static dateformat: string = "yyyy-MM-dd";
    public static generateOTPSuccessMsg: string = "Please check your email for OTP.";
    public static serverUrl: string = "https://client.anaxee.com/";

};
