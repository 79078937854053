import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import MyConstant from "../../config/my-constant";

import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'import-cases-confirm-dialog',
  templateUrl: './import-cases-confirm-dialog.html',
})
export class ImportCasesConfirmDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<ImportCasesConfirmDialogComponent>) { }

  onNoClick(): void {
    this.dialogRef.close();
  }

}


