import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiService } from '../services/api.service';


@Injectable()
export class MasterDataService {
    result: any;
    constructor(
        private http: HttpClient,
        private apiService: ApiService,
    ) { }
    /**
     * @description : this method calls "base_url+dropdown/getDropdown" if success then return dropdown with key and value otherwise send error.
     * @param :
     * @returns : dropdown with key and value
     * @author : Roli Jain
     */

    getDropdown() {
        this.apiService.postDataToService('dropdown/getDropdown', {})
            .subscribe(
                res => {
                    try {
                        this.result = JSON.stringify(res);
                        //Store type data in to local storage
                        var typeData: any = [];
                        var types = Object.values(res.data.resultDropdown.module_fields.type.options);

                        for (var _i = 0; _i < types.length; _i++) {
                            var typeOption: any = {};
                            var t = Object.values(types[_i]);
                            typeOption.name = t[0];
                            typeOption.value = t[1];
                            typeData.push(typeOption);

                        }
                        //Store bgvstatus data in to local storage
                        var bgvData: any = [];
                        var bgvStatus = Object.values(res.data.resultDropdown.module_fields.bgvstatus_c.options);
                        for (var _i = 0; _i < bgvStatus.length; _i++) {
                            var bgvOption: any = {};
                            var b = Object.values(bgvStatus[_i]);
                            bgvOption.name = b[0];
                            bgvOption.value = b[1];
                            bgvData.push(bgvOption);
                        }

                        //Store customer portal data in to local storage
                        var customerPortalData: any = [];
                        var customerPortal = Object.values(res.data.resultDropdown.module_fields.customer_portal_status_c.options);
                        for (var _c = 0; _c < customerPortal.length; _c++) {
                            var cPOption: any = {};
                            var c = Object.values(customerPortal[_c]);
                            cPOption.name = c[0];
                            cPOption.value = c[1];
                            customerPortalData.push(cPOption);
                        }

                        //Store state data in to local storage
                        var stateData: any = [];
                        var stateStatus = Object.values(res.data.resultDropdown.module_fields.stateindia_c.options);
                        for (var _i = 0; _i < stateStatus.length; _i++) {
                            var stateOption: any = {};
                            var b = Object.values(stateStatus[_i]);
                            stateOption.name = b[0];
                            stateOption.value = b[1];
                            stateData.push(stateOption);
                        }

                        //Store district data in to local storage
                        var districtData: any = [];
                        var districtStatus = Object.values(res.data.resultDropdown.module_fields.districtindiadropdown_c.options);
                        for (var _i = 0; _i < districtStatus.length; _i++) {
                            var districtOption: any = {};
                            var b = Object.values(districtStatus[_i]);
                            districtOption.name = b[0];
                            districtOption.value = b[1];
                            districtData.push(districtOption);
                        }

                        // Store followupNote data in to local storage
//                        var followupNoteData: any = [];
//                        var followupNoteStatus = Object.values(res.data.resultDropdown.module_fields.followupnote_c.options);
//                        for (var _i = 0; _i < followupNoteStatus.length; _i++) {
//                            var followupNoteOption: any = {};
//                            var f = Object.values(followupNoteStatus[_i]);
//                            followupNoteOption.name = f[0];
//                            followupNoteOption.value = f[1];
//                            followupNoteData.push(followupNoteOption);
//                        }
                       // console.log("Typeon service "+typeData);
                        localStorage.setItem('type', JSON.stringify(typeData));
                        localStorage.setItem('bgvStatus', JSON.stringify(bgvData));
                        localStorage.setItem('customerPortal', JSON.stringify(customerPortalData));
                        localStorage.setItem('customerState', JSON.stringify(stateData));
                        localStorage.setItem('customerDistrict', JSON.stringify(districtData));
//                        localStorage.setItem('followupNote', JSON.stringify(followupNoteData));

                        console.log("get-dropdown-done");
                    } catch (e) {
                        console.log('Success Exception LoginComponent login ' + e);
                    }
                },
                err => {
                    try {

                    } catch (e) {
                        console.log('Error Exception LoginComponent login ' + e);
                    }
                }
            );
    }
    /**
         * @description : this method calls "base_url+cases/getStates" if success then return dropdown with key and value otherwise send error.
         * @param :
         * @returns : All state data
         * @author : Priyanka Pathak
         */

    getStates() {
        this.apiService.postDataToService('cases/getStates', {})
            .subscribe(
                res => {
                    try {
                        this.result = res.data;
                        // console.log("state result "+this.result.length);
                        //Store type data in to local storage
                        var stateData: any = [];
                        // var types = Object.values(res.data.resultDropdown.module_fields.type.options);

                        for (var _i = 0; _i < this.result.length; _i++) {
                            var stateDataOption: any = {};
                            //var f = Object.values(this.result[_i].name);
                            stateDataOption.name = this.result[_i].name;
                            stateData.push(stateDataOption);
                        }
                        //console.log("type of"+stateData);
                        localStorage.setItem('stateData', JSON.stringify(stateData));
                    } catch (e) {
                        console.log('Success Exception LoginComponent login ' + e);
                    }
                },
                err => {
                    try {

                    } catch (e) {
                        console.log('Error Exception LoginComponent login ' + e);
                    }
                }
            );
    }

    /**
         * @description : this method calls "base_url+cases/getDistrict" if success then return dropdown with key and value otherwise send error.
         * @param :
         * @returns : All District data
         * @author : Priyanka Pathak
         */

    getDistrict() {
        this.apiService.postDataToService('cases/getDistrict', {})
            .subscribe(
                res => {
                    try {
                        this.result = res.data;
                        //Store type data in to local storage
                        var districtData: any = [];
                        // var types = Object.values(res.data.resultDropdown.module_fields.type.options);

                        for (var _i = 0; _i < this.result.length; _i++) {
                            var districtDataOption: any = {};
                            //var f = Object.values(this.result[_i].name);
                            districtDataOption.name = this.result[_i].name;
                            districtData.push(districtDataOption);
                        }
                        //console.log("type of"+districtData);
                        localStorage.setItem('districtData', JSON.stringify(districtData));
                    } catch (e) {
                        console.log('Success Exception LoginComponent login ' + e);
                    }
                },
                err => {
                    try {

                    } catch (e) {
                        console.log('Error Exception LoginComponent login ' + e);
                    }
                }
            );
    }
    /**
     * @description : this method calls "base_url+cases/getPincode" if success then return dropdown with key and value otherwise send error.
     * @param :
     * @returns : All District data
     * @author : Priyanka Pathak
     */

    getPincode() {
        this.apiService.postDataToService('cases/getPincode', {})
            .subscribe(
                res => {
                    try {
                        this.result = res.data;
                        // console.log("state result "+this.result.length);
                        //Store type data in to local storage
                        var pincodeData: any = [];
                        // var types = Object.values(res.data.resultDropdown.module_fields.type.options);

                        for (var _i = 0; _i < this.result.length; _i++) {
                            var pincodeDataOption: any = {};
                            //var f = Object.values(this.result[_i].name);
                            pincodeDataOption.name = this.result[_i].name;
                            pincodeData.push(pincodeDataOption);
                        }
                        localStorage.setItem('pincodeData', JSON.stringify(pincodeData));
                    } catch (e) {
                        console.log('Success Exception LoginComponent login ' + e);
                    }
                },
                err => {
                    try {

                    } catch (e) {
                        console.log('Error Exception LoginComponent login ' + e);
                    }
                }
            );
    }

    /**
     * @description : this method calls "base_url+cases/getStates" if success then return dropdown with key and value otherwise send error.
     * @param :
     * @returns : All state data
     * @author : Priyanka Pathak
     */

    checkBGVId(CheckIds: any) {
        this.apiService.postDataToService('case/checkbgvid', { CheckIds: CheckIds })
            .subscribe(
                res => {
                    try {
                        this.result = res.data;
                        //Store checkbgvid data in to local storage
                        var bgvcheckidData: any = [];
                        // var types = Object.values(res.data.resultDropdown.module_fields.type.options);

                        for (var _i = 0; _i < this.result.length; _i++) {
                            var bgvcheckidDataOption: any = {};
                            bgvcheckidDataOption.name = this.result[_i].bgvcheckid_c;
                            bgvcheckidData.push(bgvcheckidDataOption);
                        }
                        localStorage.setItem('bgvcheckidData', JSON.stringify(bgvcheckidData));
                    } catch (e) {
                        console.log('Success Exception LoginComponent login ' + e);
                    }
                },
                err => {
                    try {

                    } catch (e) {
                        console.log('Error Exception LoginComponent login ' + e);
                    }
                }
            );
    }
}
