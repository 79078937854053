import { Component, OnInit, HostListener } from '@angular/core';
import { SideBarService } from '../sidebar-service';
import MyContstant from "../../config/my-constant";
import { Router } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';
import { MasterDataService } from '../services/master-data.service';
import { MatDialog, MatDialogRef } from '@angular/material';
import { NotificationService } from '../services/notification.service';
import { CasesService } from '../services/cases.service';
import { CaseDetailDialogComponent } from '../cases-grid/case-detail-dialog';


import { first } from 'rxjs/operators';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  CaseDetailDialogRef: MatDialogRef<CaseDetailDialogComponent>;


  account_id: string;
  currentUser: any;
  headers: any;
  result: any;
  notificationCount: number = 0;
  headerImage: any = MyContstant.sidebar_collapsed;
  sidebarOpen: any = MyContstant.sidebar_collapsed;

  hoverIndex: number = -1;
  notificationId: string = '';



  public caseNumber: string = '';

  //for notification details
  notificationData: any = [];

  constructor(
    private sideBarService: SideBarService,
    private authenticationService: AuthenticationService,
    private router: Router,
    private masterDataService: MasterDataService,
    private dialog: MatDialog,
    private NotificationService: NotificationService,
    private CasesService: CasesService

  ) {

    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
  }

  mouseEnter(e, notification) {
    notification.ishover = true;

  }
  mouseLeave(e, notification) {
    notification.ishover = false;
  }

  ngOnInit() {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.account_id = this.currentUser.user.account_id;
    this.GetNotification(this.account_id);
  }
  GetNotification(account_id) {
    this.NotificationService.getNotification(account_id)
      .pipe(first())
      .subscribe(
        res => {

          if (JSON.stringify(res.status)) {
            this.notificationCount = res.data.result.length;
            this.notificationData = res.data.result;
            for(var ii = 0; ii < this.notificationData.length;ii++){
              this.notificationData[ii]['ishover'] = false;
            }
          }
        },
        err => {
          if (err.error.status == 'error') {
            console.log(JSON.stringify(err.error.message));
            // return to login
            this.router.navigate(['']);
          }
        }
      );

  }
  toggelSidebar(e: any) {
    this.sideBarService.toggle();
    this.sidebarOpen = !this.sidebarOpen;
    setTimeout(() => {
      this.headerImage = this.sidebarOpen;
    });
  }

  ngOnDestroy() {

    this.sideBarService.reset();
    MyContstant.sidebar_collapsed = true;
    this.headerImage = MyContstant.sidebar_collapsed;
    this.sidebarOpen = MyContstant.sidebar_collapsed;
    }

  /**
     * @description : this method remove user from session storage.
     *
     * @author : Roli Jain <roli.jain@icorprated.com>
     */



  onLogout(event: Event): void {
    event.preventDefault(); // Prevents browser following the link
    // remove user from session storage to log out
    localStorage.removeItem('currentUser');
  }

  // archive the notification
  archivedNotification(notification_id) {   
    this.NotificationService.archivedNotification(notification_id)
      .pipe(first())
      .subscribe(
        res => {
          //console.log("archive " + JSON.stringify(res));
          //api call to get all notification
          this.GetNotification(this.account_id);
        },
        err => {
          console.log("archive err " + err);
        }
      );
  }
  
  //open case details dialog box and make it read
  openCaseDialog(case_id, notification_id) {
    this.caseNumber = case_id;


    this.CasesService.saveCaseNumber(this.caseNumber);
    this.CaseDetailDialogRef = this.dialog.open(CaseDetailDialogComponent, {
      panelClass: 'notificationCaseDetails'
    });
    this.NotificationService.readNotification(notification_id)
      .pipe(first())
      .subscribe(
        res => {
          //console.log("archive " + JSON.stringify(res));
          //api call to get all notification
          this.GetNotification(this.account_id);
        },
        err => {
          if (err.error.status == 'error') {
            console.log('Error:' + JSON.stringify(err.error.message).split('"').join(""));
          }
        }
      );
  }

  onHover(i: number) {
    this.hoverIndex = i;
  }






}