import {
  Component,
  OnInit,
  ViewChild,
  Output,
  ElementRef,
  TemplateRef
} from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl
} from "@angular/forms";
import { CasesService } from "../services/cases.service";
import { ActivatedRoute, Router } from "@angular/router";
import { DatePipe, formatDate } from "@angular/common";
import { MatDialog, MatDialogRef } from "@angular/material";
import { CaseDetailDialogComponent } from "../cases-grid/case-detail-dialog";
import * as moment from "moment";
import { MatTabChangeEvent, MatTabNav } from "@angular/material";
import { first, count } from "rxjs/operators";
import { ExcelService } from "../services/excel.service";
//import * as XLSX from 'xlsx';
import MyConstant from "../../config/my-constant";
import { ApiService } from "../services/api.service";
import { QueryDetailDialogComponent } from "../cases-grid/case-detail-dialog";
import { HolidayDetailDialogComponent } from "../cases-grid/case-detail-dialog";

import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MasterDataService } from "../services/master-data.service";
import { DashboardService } from "../services/dashboard.service";

@Component({
  selector: "app-cases-grid",
  templateUrl: "./cases-grid.component.html",
  styleUrls: ["./cases-grid.component.scss"],
  providers: [DatePipe]
})
export class CasesGridComponent implements OnInit {
  showLoader = true;
  color = "#5cc6d0";
  mode = "indeterminate";
  value = 50;

  // List of links
  links = [
    {
      label: "All Cases",
      path: "/cases",
      key: "total_alloted_cases_count",
      count: ""
    },
    {
      label: "Queries raised",
      path: "/cases/QueryRaised",
      key: "query_raised_count",
      count: ""
    },
    {
      label: "Unreplied Queries",
      path: "/cases/UnrepliedQueries",
      key: "unreplied_query_count",
      count: ""
    },
    {
      label: "Out of TAT",
      path: "/cases/OutofTAT",
      key: "outoftat_count",
      count: ""
    },
    {
      label: "Stop Check",
      path: "/cases/StopCheck",
      key: "stop_check_count",
      count: ""
    }
  ];

  CaseDetailDialogRef: MatDialogRef<CaseDetailDialogComponent>;
  QueryDetailDialogRef: MatDialogRef<QueryDetailDialogComponent>;
  HolidayDetailDialogRef: MatDialogRef<HolidayDetailDialogComponent>;

  ExcelService: any;
  @ViewChild("actionTmpl") actionTmpl: TemplateRef<any>;
  @ViewChild("queryTmpl") queryTmpl: TemplateRef<any>;
  @ViewChild("agingTmpl") agingTmpl: TemplateRef<any>;
  @ViewChild("holidayTmpl") holidayTmpl: TemplateRef<any>;
  @ViewChild("snTmpl") snTmpl: TemplateRef<any>;

  CaseForm: FormGroup;
  submitted = false;
  currentUser: any;
  account_id: string;
  serverError: string;
  Result: any;
  rows: any[] = [];
  columns: any;
  CaseData: any[] = [];
  export: any;
  TypeData: any;
  CustomerPortalStatus: any;
  CustomerPortalState: any;
  CustomerPortalDistrict: any;
  followupNote: any;
  type: any;
  slider: any;
  nextOffset: number;
  lastOffset: number = 0;
  finalData: any[] = [];
  search: string = "";
  caseStatus: string = "";
  caseType: string = "";
  caseAgingStart: number = 0;
  caseAgingEnd: number = 0;
  caseSortColumn: string = "";
  caseSortOrder: string = "";
  offset: number = 0;
  to_date: any;
  from_date: any;
  param_tab: string = "";
  param_sub: any = "";
  IsActiveTab: string = "";
  activeTab: string = "";
  Export: any;
  linkText: string = "";
  csvName: string = "";

  //for query reply
  queryReplyList: FormGroup;
  queryReplyFromListSubmit = false;

  searchClick = false;
  resetClick = false;
  exportClick = false;

  //for holiday popup
  holiday_to_date: any;
  holiday_duedays: Number;
  stateName: string;
  bgvreportsent_c: any;
  bgvduedate_c: any;

  date: Date;
  defaultDate: string;
  dashboard_to_date: any;
  dashboard_from_date: any;
  showingNumber: Number;
  totalRecords: Number;
  minDate: moment.Moment;

  constructor(
    private excelService: ExcelService,
    private CasesService: CasesService,
    private router: Router,
    private formBuilder: FormBuilder,
    private datePipe: DatePipe,
    private el: ElementRef,
    private actv_route: ActivatedRoute,
    private dialog: MatDialog,
    private apiService: ApiService,
    private masterDataService: MasterDataService,
    private dashboardService: DashboardService
  ) {
    //store type,bgvstatus,customer portal value in local storage
    this.masterDataService.getDropdown();
    this.minDate = moment().subtract(3, "month");
  }
  private caseNumber: string = "";
  private queryId: string = "";

  changePath(path: string) {
    //all case without dashboard date range
    // this.CasesService.saveDashboardDate('');
    this.showLoader = true;
    this.activeTab = path;

    let vv = this.router.createUrlTree([path]).toString();
    this.router.navigate([vv]);
  }

  getCaseCount(defaultDate, from_date, to_date) {
    this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
    this.account_id = this.currentUser.user.account_id;

    //  alert(defaultDate + ' search');

    this.dashboardService
      .getCaseCountCaseGrid(this.account_id, defaultDate, to_date, from_date)
      .pipe(first())
      .subscribe(
        res => {
          if (res.status) {
            for (let linkIndex in this.links) {
              this.links[linkIndex].count =
                res.data.result[this.links[linkIndex].key];

              this.totalRecords = res.data.result["total_alloted_cases_count"];

              this.param_sub = this.actv_route.params.subscribe(params => {
                if (params.hasOwnProperty("name")) {
                  this.param_tab = params["name"];

                  switch (this.param_tab) {
                    case "Open":
                      this.totalRecords = res.data.result["open_cases_count"];
                      break;
                    case "InProgress":
                      this.totalRecords =
                        res.data.result["case_in_progress_count"];
                      break;
                    case "Completed":
                      this.totalRecords =
                        res.data.result["cases_completed_count"];
                      break;
                    case "ReportReceivedQAPending":
                      this.totalRecords = res.data.result["qa_pending_count"];
                      break;
                    case "StopCheck":
                      this.totalRecords = res.data.result["stop_check_count"];
                      break;
                    case "UnrepliedQueries":
                      this.totalRecords =
                        res.data.result["unreplied_query_count"];
                      break;
                    case "QueryRaised":
                      this.totalRecords = res.data.result["query_raised_count"];
                      break;
                    case "OutofTAT":
                      this.totalRecords = res.data.result["outoftat_count"];
                      break;
                    default:
                      this.totalRecords =
                        res.data.result["total_alloted_cases_count"];
                  }
                }
              });
            }
          }
        },
        err => {
          return 0;
        }
      );
  }

  ngOnInit() {
    this.defaultDate = this.CasesService.fetchDashboardDate();
    this.dashboard_from_date = this.CasesService.fetchDashboardFromDate();
    this.dashboard_to_date = this.CasesService.fetchDashboardToDate();

    this.getCaseCount(
      this.defaultDate,
      this.dashboard_from_date,
      this.dashboard_to_date
    );

    /**Get Master data from local storage */
    this.TypeData = JSON.parse(localStorage.getItem("type"));
    // console.log(JSON.stringify(this.TypeData));
    for (var i = 0; i < this.TypeData.length; i++) {
      if (
        this.TypeData[i].name == "None" ||
        this.TypeData[i].name == "DataCollection" ||
        this.TypeData[i].name == "FEVerification" ||
        this.TypeData[i].name == "PVCCardPrinting"
      ) {
        this.TypeData.splice(i, 4);
      }
    }
    //console.log("typedeata " + JSON.stringify(this.TypeData));
    this.CustomerPortalStatus = JSON.parse(
      localStorage.getItem("customerPortal")
    );
    this.CustomerPortalState = JSON.parse(
      localStorage.getItem("customerState")
    );
    this.CustomerPortalDistrict = JSON.parse(
      localStorage.getItem("customerDistrict")
    );
    // this.followupNote = JSON.parse(localStorage.getItem("followupNote"));

    /** Get current user id */
    this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
    this.account_id = this.currentUser.user.account_id;

    /** Get Router value from tab */

    this.param_sub = this.actv_route.params.subscribe(params => {
      if (params.hasOwnProperty("name")) {
        this.param_tab = params["name"];
        this.CaseData = [];
        this.showingNumber = 0;

        if (
          this.defaultDate != "" ||
          this.dashboard_to_date != "" ||
          this.dashboard_from_date != ""
        ) {
          //from dashboard redirect

          this.GetCasesData(
            0,
            "",
            "",
            "",
            "",
            "",
            this.caseSortColumn,
            this.caseSortOrder,
            this.param_tab,
            this.dashboard_to_date,
            this.dashboard_from_date,
            this.defaultDate
          );
        } else {
          //default case

          this.GetCasesData(
            0,
            "",
            "",
            "",
            "",
            "",
            this.caseSortColumn,
            this.caseSortOrder,
            this.param_tab,
            "",
            "",
            ""
          );
        }

        this.IsActiveTab = this.param_tab;

        if (
          this.param_tab == "Open" ||
          this.param_tab == "InProgress" ||
          this.param_tab == "Completed" ||
          this.param_tab == "ReportReceivedQAPending"
        ) {
          this.activeTab = "/cases";
        } else {
          this.activeTab = "/cases/" + this.param_tab;
        }

        switch (this.param_tab) {
          case "Open":
            this.linkText = "(Open)";
            this.csvName = "Open_list";
            break;
          case "InProgress":
            this.linkText = "(In Progress)";
            this.csvName = "IN_Progress_list";
            break;
          case "Completed":
            this.linkText = "(Completed)";
            this.csvName = "Completed_list";
            break;
          case "ReportReceivedQAPending":
            this.linkText = "(Report Received QA Pending)";
            this.csvName = "Report_Received_QA_Pending_list";
            break;
          case "StopCheck":
            this.linkText = "(Stop Check)";
            this.csvName = "Stop_Check_list";
            break;
          case "UnrepliedQueries":
            this.linkText = "(Unreplied Queries)";
            this.csvName = "Unreplied_Queries_list";
            break;
          case "QueryRaised":
            this.linkText = "(Query Raised)";
            this.csvName = "Query_Raised_list";
            break;
          case "OutofTAT":
            this.linkText = "(Out of TAT)";
            this.csvName = "Out_of_TAT_list";
            break;
          default:
            this.linkText = "";
            this.csvName = "Case_list";
        }
      } else {
        if (
          this.defaultDate != "" ||
          this.dashboard_to_date != "" ||
          this.defaultDate != ""
        ) {
          //from dashboard redirect
          this.GetCasesData(
            0,
            "",
            "",
            "",
            "",
            "",
            this.caseSortColumn,
            this.caseSortOrder,
            "",
            this.dashboard_to_date,
            this.dashboard_from_date,
            this.defaultDate
          );
        } else {
          //default case
          this.GetCasesData(
            0,
            "",
            "",
            "",
            "",
            "",
            this.caseSortColumn,
            this.caseSortOrder,
            "",
            "",
            "",
            ""
          );
        }

        this.activeTab = "/cases";
      }
    });

    //Case form Validation
    this.CaseForm = this.formBuilder.group({
      search: [],
      caseStatus: [],
      caseType: [],
      range: [],
      DateRange: [],
      caseAgingStart: [],
      caseAgingEnd: []
    });
  }

  // convenience getter for easy access to form fields of otp form
  get f() {
    return this.CaseForm.controls;
  }

  /**
   * @description : this methsearod calls to get data according to search criteria.
   * sear
   * @author : Priyanka Pathak
   */
  onCaseSubmit(nextOffset: number = 0) {
    // stop here if form is invalid
    if (this.CaseForm.invalid) {
      return;
    }
    // this.exportdata();
    this.submitted = true;
    this.search = this.f.search.value;
    this.caseStatus = this.f.caseStatus.value;
    this.caseType = this.f.caseType.value;
    this.caseAgingStart = this.f.caseAgingStart.value;
    this.caseAgingEnd = this.f.caseAgingEnd.value;
    // this.caseAgingStart = this.rangeslideData2.range_start;
    // this.caseAgingEnd = this.rangeslideData2.range_end;
    console.log(
      "caseAgingStart " +
        this.caseAgingStart +
        "caseAgingEnd " +
        this.caseAgingEnd
    );
    if (this.f.DateRange.value) {
      //empty default date and days of dashboard
      this.CasesService.saveDashboardToDate("");
      this.CasesService.saveDashboardFromDate("");
      this.CasesService.saveDashboardDate("");

      this.from_date = moment(this.f.DateRange.value.start).format();
      this.to_date = moment(this.f.DateRange.value.end).format();

      this.getCaseCount("", this.to_date, this.from_date);

      this.defaultDate = this.CasesService.fetchDashboardDate();
      this.dashboard_from_date = this.CasesService.fetchDashboardFromDate();
      this.dashboard_to_date = this.CasesService.fetchDashboardToDate();
    }

    if (
      this.defaultDate != "" ||
      this.dashboard_to_date != "" ||
      this.dashboard_from_date != ""
    ) {
      //from dashboard redirect
      this.GetCasesData(
        nextOffset,
        this.caseType,
        this.caseStatus,
        this.search,
        this.caseAgingStart,
        this.caseAgingEnd,
        this.caseSortColumn,
        this.caseSortOrder,
        this.param_tab,
        this.dashboard_to_date,
        this.dashboard_from_date,
        this.defaultDate
      );
    } else {
      //default case

      this.GetCasesData(
        nextOffset,
        this.caseType,
        this.caseStatus,
        this.search,
        this.caseAgingStart,
        this.caseAgingEnd,
        this.caseSortColumn,
        this.caseSortOrder,
        this.param_tab,
        this.from_date,
        this.to_date,
        ""
      );
    }
    //this.isLoading = false;
  }

  //on search buttton click empty the previous filled values in case array
  onSearch() {
    this.searchClick = true;
    this.exportClick = false;
    this.resetClick = false;
    this.showLoader = true;
    this.CaseData = [];
    this.showingNumber = 0;
  }

  //on reset button click reset case form
  onReset() {
    this.resetClick = true;
    this.searchClick = false;
    this.exportClick = false;

    this.CaseForm.reset();
    // this.startRange2value = 10;
    //this.rangeslideData2 = { range_start: 0, range_end: 300 };
    window.location.reload();
  }
  //
  /**
   * @description : this method calls case api to fetch data of cases.
   *
   * @author : Priyanka Pathak
   */
  GetCasesData(
    offset: number,
    caseType: string,
    caseStatus: string,
    search: any,
    caseAgingStart: any,
    caseAgingEnd: any,
    caseSortColumn: string,
    caseSortOrder: string,
    param_tab: string,
    from_date: any,
    to_date: any,
    dashboardDays: any
  ) {
    this.CasesService.CasesData(
      offset,
      caseType,
      caseStatus,
      search,
      caseAgingStart,
      caseAgingEnd,
      caseSortColumn,
      caseSortOrder,
      param_tab,
      from_date,
      to_date,
      dashboardDays
    ).subscribe(
      res => {
        this.Result = res.data.cases;

        if (this.showingNumber == undefined) {
          this.showingNumber = 0;
        }
        this.showingNumber = this.showingNumber + this.Result.length;

        this.nextOffset = res.data.next_offset;
        let s_no = offset + 1;

        this.Result.forEach(Result => {
          let duedays_c: string = "";
          if (Result.duedays_c != null && Result.duedays_c != "") {
            duedays_c = Result.duedays_c;
          }

          let c_data: any = {};
          c_data.s_no = s_no;
          c_data.bgvcheckid_c = Result.bgvcheckid_c;
          c_data.bgvclientname_c = Result.bgvclientname_c;
          c_data.stateindia_c = this.GetState(Result.stateindia_c);
          c_data.districtindiadropdown_c = this.GetDistrict(
            Result.districtindiadropdown_c
          );
          c_data.customer_portal_status_c = this.GetCustomerStatus(
            Result.customer_portal_status_c
          );
          c_data.date_entered = this.datePipe.transform(
            Result.date_entered,
            MyConstant.dateformat
          );

          let bgvduedate_c: string = "";

          if (Result.bgvduedate_c != null && Result.duedays_c != "") {
            bgvduedate_c = this.datePipe.transform(
              Result.bgvduedate_c,
              MyConstant.dateformat
            );
          }
          let EDOC_DUE_DATE: string = "";
          if (bgvduedate_c != "" && duedays_c != "") {
            EDOC_DUE_DATE = bgvduedate_c + ", " + duedays_c;
          } else if (
            bgvduedate_c == "" &&
            (duedays_c != "" || duedays_c != null)
          ) {
            if (duedays_c == "" || duedays_c == null) {
              EDOC_DUE_DATE = "7";
            } else {
              EDOC_DUE_DATE = duedays_c;
            }
          } else if (duedays_c == "" || duedays_c == null) {
            EDOC_DUE_DATE = bgvduedate_c + ", 7";
          }

          c_data.bgvduedate_c = EDOC_DUE_DATE;
          c_data.bgvreportsent_c = this.datePipe.transform(
            Result.bgvreportsent_c,
            MyConstant.dateformat
          );
          c_data.case_aging = Result.case_aging;
          c_data.name = Result.name;
          c_data.edoc_due_date = Result.bgvduedate_c;

          let holidays_c: number = 0;
          if (
            Result.holidays_c != "" ||
            Result.holidays_c != "null" ||
            Result.holidays_c != null
          ) {
            holidays_c = Result.holidays_c;
          }

          c_data.holidays_c = holidays_c;
          c_data.case_number = Result.case_number;
          c_data.query_id = Result.query_id;
          c_data.duedays_c = Result.duedays_c;
          c_data.stateindiaKey = Result.stateindia_c;

          if (
            Result.query_response == "null" ||
            Result.query_response == "" ||
            Result.query_response == null ||
            Result.query_response == "NULL"
          ) {
            if (
              Result.name == "null" ||
              Result.name == "" ||
              Result.name == null ||
              Result.name == "NULL"
            ) {
              c_data.query_response = "false";
            } else {
              c_data.query_response = "true";
            }
          } else {
            c_data.query_response = "false";
          }

          this.CaseData.push(c_data);
          s_no++;
        });

        this.rows = this.CaseData;

        this.columns = [
          {
            prop: "s_no",
            name: "#",
            width: 40,
            sortable: false,
            headerClass: "snNoClass",
            cellTemplate: this.snTmpl
          },
          {
            prop: "bgvcheckid_c",
            name: "Check ID",
            width: 125,
            cellTemplate: this.actionTmpl
          },
          { prop: "bgvclientname_c", name: "Client Name", width: 180 },
          { prop: "stateindia_c", name: "State", width: 130 },
          { prop: "districtindiadropdown_c", name: "District", width: 120 },
          {
            prop: "customer_portal_status_c",
            name: "Status",
            width: 70,
            sortable: false
          },
          { prop: "date_entered", name: "Job Received Date", width: 125 },
          {
            prop: "bgvduedate_c",
            name: "EDOC, Due days",
            width: 100,
            sortable: false
          },
          { prop: "bgvreportsent_c", name: "Report Sent Date", width: 90 },
          {
            prop: "case_aging",
            name: "Aging",
            width: 60,
            sortable: false,
            cellTemplate: this.agingTmpl
          },
          {
            prop: "name",
            name: "Query Details",
            width: 250,
            sortable: false,
            cellTemplate: this.queryTmpl
          },
          {
            prop: "holidays_c",
            name: "Holidays",
            width: 100,
            cellTemplate: this.holidayTmpl
          },
          { prop: "case_number", name: "Case Number", width: 0 },
          { prop: "query_id", name: "Query Id", width: 0 },
          { prop: "duedays_c", name: "duedays_c", width: 0 },
          { prop: "stateindiaKey", name: "stateindiaKey", width: 0 },
          { prop: "query_response", name: "query_response", width: 0 }
        ];

        this.showLoader = false;
      },
      err => {
        console.log("Error:" + JSON.stringify(err));
        this.showLoader = false;
      }
    );
  }

  /**
   * @description : this method will populate name of key from type of verification.
   * @param : any
   * @returns : Name
   * @author : Priyanka Pathak
   */

  GetCustomerStatus(d: any) {
    let VAL = "";
    this.CustomerPortalStatus.forEach(CustomerStatus => {
      if (d === CustomerStatus.name) {
        VAL = CustomerStatus.value;
      }
    });
    return VAL;
  }
  /**
   * @description : this method will populate name of key from State.
   * @param : any
   * @returns : Name
   * @author : Priyanka Pathak
   */

  GetState(d: any) {
    let VAL = "";
    this.CustomerPortalState.forEach(CustomState => {
      if (d == CustomState.name) {
        VAL = CustomState.value;
      }
    });
    return VAL;
  }
  /**
   * @description : this method will populate name of key from type of verification.
   * @param : any
   * @returns : Name
   * @author : Priyanka Pathak
   */

  GetDistrict(d: any) {
    let VAL = "";
    this.CustomerPortalDistrict.forEach(CustomerDistrict => {
      if (d == CustomerDistrict.name) {
        VAL = CustomerDistrict.value;
      }
    });
    return VAL;
  }

  /**
   * @description : this method will populate name of key from type of followupnote.
   * @param : any
   * @returns : Name
   * @author : Priyanka Pathak
   */

  GetFollowupNote(d: any) {
    let VAL = "";
    this.followupNote.forEach(followupNote => {
      if (d == followupNote.name) {
        VAL = followupNote.value;
      }
    });
    return VAL;
  }

  //settings for ngx data table to fetch cases
  readonly headerHeight = 50;
  readonly rowHeight = 50;
  readonly footerHeight = 50;
  isLoading: boolean = false;
  readonly pageLimit = 10;
  startRange2value: number = 0;
  rangeslideData2 = { range_start: 0, range_end: 50 };

  //==================Server side sorting==================
  onSort(event) {
    this.CaseData = [];
    this.showingNumber = 0;
    // event was triggered, start sort sequence
    //console.log('Sort Event', event);

    this.isLoading = true;
    // emulate a server request with a timeout
    setTimeout(() => {
      const rows = [this.rows];
      const sort = event.sorts[0];
      //console.log("sort " + this.caseType + "dir " + sort.dir);
      //Call Get cases API to sort column wise data
      this.caseSortColumn = sort.prop;
      this.caseSortOrder = sort.dir;
      this.onCaseSubmit();
      this.isLoading = false;
    }, 1000);
  }

  //==============Server side page loading===========================
  onScroll(offsetY: number) {
    this.isLoading = true;

    if (!this.nextOffset) {
      return;
    }
    if (this.nextOffset != this.lastOffset) {
      this.onCaseSubmit(this.nextOffset);
      this.lastOffset = this.nextOffset;
    }
  }

  //Open case details dialog
  openDialog(obj) {
    this.caseNumber = obj.case_number;
    this.CasesService.saveCaseNumber(this.caseNumber);
    this.CaseDetailDialogRef = this.dialog.open(CaseDetailDialogComponent, {
      panelClass: "caseDetailDialogWrapper"
    });
  }

  //Open query details dialog
  openQueryDialog(obj) {
    this.queryId = obj.query_id;
    this.CasesService.saveQueryId(this.queryId);
    this.QueryDetailDialogRef = this.dialog.open(QueryDetailDialogComponent, {
      panelClass: "queryDetailDialogWrapper"
    });
  }

  //Open holiday details dialog
  openHolidayDialog(obj) {
    this.holiday_to_date = obj.date_entered;
    this.holiday_duedays = obj.duedays_c;
    this.stateName = obj.stateindiaKey;
    this.bgvreportsent_c = obj.bgvreportsent_c;
    this.bgvduedate_c = obj.edoc_due_date;

    if (
      this.holiday_to_date == "" ||
      this.holiday_to_date == "null" ||
      this.holiday_to_date == null
    ) {
      this.holiday_to_date = formatDate(new Date(), "yyyy-MM-dd", "en");
    }

    if (this.holiday_duedays == null) {
      this.holiday_duedays = 7;
    }

    this.CasesService.saveToDate(this.holiday_to_date);
    this.CasesService.saveState(this.stateName);
    this.CasesService.saveDueDays(this.holiday_duedays);
    this.CasesService.saveBgvReport(this.bgvreportsent_c);
    this.CasesService.saveBgvDueDate(this.bgvduedate_c);

    this.HolidayDetailDialogRef = this.dialog.open(
      HolidayDetailDialogComponent,
      {
        panelClass: "holidayDetailDialogWrapper"
      }
    );
  }

  exportdata() {
    let DD = "";

    if (
      this.defaultDate != "" ||
      this.dashboard_to_date != "" ||
      this.dashboard_from_date != ""
    ) {
      //from dashboard redirect
      this.from_date = this.dashboard_to_date;
      this.to_date = this.dashboard_from_date;
      DD = this.defaultDate;
    } else {
      //default case
    }

    this.CaseData = [];

    this.exportClick = true;
    this.resetClick = false;
    this.searchClick = false;

    this.CasesService.exportCases(
      this.offset,
      this.caseType,
      this.caseStatus,
      this.search,
      this.caseAgingStart,
      this.caseAgingEnd,
      this.caseSortColumn,
      this.caseSortOrder,
      this.param_tab,
      this.from_date,
      this.to_date,
      DD
    )
      .pipe(first())
      .subscribe(
        res => {
          if (JSON.stringify(res.status)) {
            res.data.forEach(function(key) {
              delete key["query_id"];
              delete key["name"];
              delete key["id"];
              delete key["bgvstatus_c"];
            });
            if (this.csvName == "") {
              this.csvName = "Case_list";
            }
            this.excelService.exportAsExcelFile(res.data, this.csvName);
          }
        },
        err => {
          if (err.error.status == "error") {
            console.log(
              "Error:" +
                JSON.stringify(err.error.message)
                  .split('"')
                  .join("")
            );
          }
        }
      );
  }

  exportAsExcelFile(): void {
    var data1: any = this.exportdata();
    this.excelService.exportAsExcelFile(data1, "Report");
  }
}
