import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule, routingComponent } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatRadioModule } from '@angular/material/radio';
import { FlexLayoutModule } from '@angular/flex-layout';
import { SideBannerComponent } from './side-banner/side-banner.component';
import { HeaderComponent } from './header/header.component';
import { MatIconModule } from '@angular/material/icon';
import { FooterComponent } from './footer/footer.component';
import {
  MatSelectModule, MatButtonModule, MatCheckboxModule, MatSidenavModule, MatInputModule, MatDialogModule,

} from '@angular/material';
import { SidebarComponent } from './sidebar/sidebar.component';
import { SideBarService } from './sidebar-service';
import { MatTabsModule } from '@angular/material/tabs';
import { ReactiveFormsModule, FormControl, FormsModule, FormGroup } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { MatSlidefModule } from './../slidef';
import { AlertService } from './services/alert.service';
import { AuthenticationService } from './services/authentication.service';
import { HttpModule } from '@angular/http';
import { ApiService } from './services/api.service';
import { CasesService } from './services/cases.service';
import { DashboardService } from './services/dashboard.service';
import { MasterDataService } from './services/master-data.service';
import { NotificationService } from './services/notification.service';
import { AuthMiddleware } from './services/auth.middleware.service';
import { MoveNextByMaxLengthDirective } from './directives/move-next-by-max-length.directive';
import { OnlynumberDirective } from './directives/onlynumber.directive';

import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { CaseDetailDialogComponent } from './cases-grid/case-detail-dialog';
import { ImportCasesDialogComponent } from './import-cases/import-cases-dialog';
import { ImportCasesConfirmDialogComponent } from './import-cases/import-cases-confirm-dialog';
import { ImportCasesCheckboxDialogComponent } from './import-cases/import-cases-checkbox-dialog';
import { ImportCasesSuccessDialogComponent } from './import-cases/import-cases-success-dialog';
import { ExcelService } from './services/excel.service';
import { QueryDetailDialogComponent } from './cases-grid/case-detail-dialog';
import { HolidayDetailDialogComponent } from './cases-grid/case-detail-dialog';
import { MatMenuModule } from '@angular/material/menu';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';

import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatBadgeModule } from '@angular/material/badge';
import { ImportCasesComponent } from './import-cases/import-cases.component';
import { ChartsModule } from 'ng2-charts';
import {MatTableModule} from '@angular/material/table';
import {MatListModule} from '@angular/material/list';

import {DatePipe} from '@angular/common';
@NgModule({
 

  declarations: [
    AppComponent,
    LoginComponent,
    routingComponent,
    SideBannerComponent,
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
    MoveNextByMaxLengthDirective,
    OnlynumberDirective,
    CaseDetailDialogComponent,
    QueryDetailDialogComponent,
    HolidayDetailDialogComponent,
    ImportCasesComponent,
    ImportCasesDialogComponent,
    ImportCasesConfirmDialogComponent,
    ImportCasesCheckboxDialogComponent,
    ImportCasesSuccessDialogComponent

  ],
  imports: [
    BrowserModule,
    HttpModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatInputModule,
    MatFormFieldModule,
    MatRadioModule,
    FlexLayoutModule,
    MatIconModule,
    MatButtonModule,
    MatCheckboxModule,
    MatSidenavModule,
    MatTabsModule,
    MatSelectModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    NgxDatatableModule,
    MatSlidefModule,
    MatDialogModule,
    MatTableModule,
    MatListModule,
    NgxDaterangepickerMd.forRoot({
      separator: ' - ',
      applyLabel: 'Okay',
      cancelLabel: 'Cancel',
    }),
    MatMenuModule,
    MatProgressSpinnerModule,
    MatBadgeModule,
    ChartsModule
  ],
  providers: [
    SideBarService,
    AlertService,
    ApiService,
    AuthenticationService,
    CasesService,
    DashboardService,
    MasterDataService,
    AuthMiddleware,
    ExcelService,
    NotificationService,
    
    DatePipe,
    { provide: LocationStrategy, useClass: HashLocationStrategy }
  ],
  bootstrap: [AppComponent],
  entryComponents: [HolidayDetailDialogComponent, CaseDetailDialogComponent, QueryDetailDialogComponent, ImportCasesDialogComponent, ImportCasesConfirmDialogComponent, ImportCasesCheckboxDialogComponent, ImportCasesSuccessDialogComponent]

})
export class AppModule { }
