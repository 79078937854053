import { Component } from '@angular/core';
import { first } from 'rxjs/operators';
import { Router } from '@angular/router';
import { CasesService } from '../services/cases.service';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { DatePipe } from '@angular/common';
import MyConstant from "../../config/my-constant";




@Component({
  templateUrl: './case-detail-dialog.html',
  providers: [DatePipe]
})
export class CaseDetailDialogComponent {

  //for case details popup
  bgvcheckid_c: string = "";
  bgvclientname_c: string = "";
  stateindia_c: string = "";
  districtindiadropdown_c: string = "";
  bgvstatus_c: string = "";
  date_entered: string = "";
  bgvduedate_c: string = "";
  bgvreportsent_c: string = "";
  case_aging: string = "";
  name: string = "";
  holidays_c: string = "";
  case_number: string = "";
  verificationType: string = "";
  bgvcandidatename_c: string = "";
  bgvaddress_c: string = "";
  bgvmobilenos_c: string = "";
  bgvpincode_c: string = "";
  followupnotes_c: string = "";
  caseName: string = "";

  //for query details
  queryData: any;

  CustomerPortalStatus: any;
  CustomerPortalState: any;
  CustomerPortalDistrict: any;
  BgvStatus: any;
  Followupnote: any;

  //for query reply
  queryReplyList: FormGroup;
  queryReplyFromListSubmit = false;
  lastResponce: string = "";
  lastQueryId: string = "";

  lastQueryResponce: boolean = false;

  constructor(
    private CasesService: CasesService,
    private router: Router,
    private formBuilder: FormBuilder,
    private datePipe: DatePipe
  ) { }

  public caseNumber = this.CasesService.fetchCaseNumber();


  ngOnInit() {
    //Query Reply from list Form validation 
    this.queryReplyList = this.formBuilder.group({
      replyFromList: ['', Validators.required]
    });

    /**Get Master data from local storage */
    this.CustomerPortalStatus = JSON.parse(localStorage.getItem('customerPortal'));
    this.CustomerPortalState = JSON.parse(localStorage.getItem('customerState'));
    this.CustomerPortalDistrict = JSON.parse(localStorage.getItem('customerDistrict'));
    this.BgvStatus = JSON.parse(localStorage.getItem('bgvStatus'));
    this.Followupnote = JSON.parse(localStorage.getItem('followupNote'));

    if (this.caseNumber) {

      this.CasesService.getCaseByNumber(this.caseNumber)
        .pipe(first())
        .subscribe(
          res => {
            if (JSON.stringify(res.status)) {

              this.bgvcheckid_c = JSON.stringify(res.data.case.bgvcheckid_c).split('"').join("");
              this.bgvclientname_c = JSON.stringify(res.data.case.bgvclientname_c).split('"').join("");
              this.stateindia_c = this.GetState(res.data.case.stateindia_c);
              this.districtindiadropdown_c = this.GetDistrict(res.data.case.districtindiadropdown_c);
              this.bgvstatus_c = this.GetBgvStatus(res.data.case.bgvstatus_c);
              this.date_entered = this.datePipe.transform(res.data.case.date_entered, MyConstant.dateformat);
              this.bgvduedate_c = this.datePipe.transform(res.data.case.bgvduedate_c, MyConstant.dateformat);
              this.bgvreportsent_c = this.datePipe.transform(res.data.case.bgvreportsent_c, MyConstant.dateformat);
              this.case_aging = this.caseNumber;
              this.name = JSON.stringify(res.data.case.name).split('"').join("");
              this.holidays_c = JSON.stringify(res.data.case.holidays_c).split('"').join("");
              this.case_number = this.caseNumber;
              this.verificationType = JSON.stringify(res.data.case.type).split('"').join("");
              this.bgvcandidatename_c = JSON.stringify(res.data.case.bgvcandidatename_c).split('"').join("");
              this.bgvaddress_c = JSON.stringify(res.data.case.bgvaddress_c).split('"').join("");
              this.bgvmobilenos_c = JSON.stringify(res.data.case.bgvmobilenos_c).split('"').join("");
              this.bgvpincode_c = JSON.stringify(res.data.case.bgvpincode_c).split('"').join("");
              // this.followupnotes_c = JSON.stringify(res.data.case.followupnote_c).split('"').join("");
              // this.followupnotes_c = this.GetFollowUp(res.data.case.customernote_c);
              this.caseName = JSON.stringify(res.data.case.name).split('"').join("");
            }
          },
          err => {
            if (err.error.status == 'error') {
              console.log(JSON.stringify(err.error.message) + 'err in case number');

            }
          }
        );

      this.CasesService.getQueryListsByCaseByNumber(this.caseNumber)
        .pipe(first())
        .subscribe(
          res => {
            if (JSON.stringify(res.status)) {
              this.queryData = res.data;

              if (res.data.length > 0) {
                this.lastQueryId = this.queryData[0].id;
                this.lastResponce = this.queryData[0].query_response;

                if (!this.lastResponce || this.lastResponce == '' || this.lastResponce == 'null' || this.lastResponce == 'NULL' || this.lastResponce == null || this.lastResponce == undefined || this.lastResponce == 'undefined') {

                } else {
                  this.lastQueryResponce = true;
                }
              }
            }
          },
          err => {
            if (err.error.status == 'error') {
              console.log(JSON.stringify(err.error.message));
            }
          }
        );
    }

  }

  // convenience getter for easy access to form fields of queryReply form 
  get f3() { return this.queryReplyList.controls; }

  //Query Reply  
  onQueryReplyFromList() {

    let queryId = this.lastQueryId;
    this.queryReplyFromListSubmit = true;
    // stop here if no response entered
    if (this.queryReplyList.invalid) {
      return;
    }

    this.CasesService.queryReply(queryId, this.f3.replyFromList.value)
      .pipe(first())
      .subscribe(
        res => {
          window.location.reload();
        },
        err => {
          console.log(JSON.stringify(err));
          if (err.error.status == 'error') {
            console.log(JSON.stringify(err.error.message));
          }
        }
      );

  }
  /**
     * @description : this method will populate name of key from Followup .
     * @param : any
     * @returns : Name
     * @author : Priyanka Pathak
     */

  GetFollowUp(d: any) {
    let VAL = '';   
    if (d != null && d != '' && d != undefined && d != 'NULL' && d !='null') {
      let splitted = d.split('^').join("");
      let followsplit = splitted.split(',');
      console.log("followsplit " + followsplit + typeof followsplit);
      for (let i = 0; i < followsplit.length; i++) {
        this.Followupnote.forEach(FollowupStatus => {
          console.log("d" + followsplit[i]);
          console.log("name " + FollowupStatus.name);
          console.log("val " + FollowupStatus.value);
          if (followsplit[i] == FollowupStatus.name) {
            VAL += FollowupStatus.value;
            if (i < followsplit.length - 1) {
              VAL += ', ';
            }
          }
        }

        );
      }
    }

    return VAL;
  }
  /**
    * @description : this method will populate name of key from type of verification.
    * @param : any
    * @returns : Name
    * @author : Priyanka Pathak
    */

  GetCustomerStatus(d: any) {
    let VAL = '';
    this.CustomerPortalStatus.forEach(CustomerStatus => {
      if (d == CustomerStatus.name) {
        VAL = CustomerStatus.value;
      }
    }
    );
    return VAL;
  }
  /**
   * @description : this method will populate name of key from State.
   * @param : any
   * @returns : Name
   * @author : Priyanka Pathak
   */

  GetState(d: any) {
    let VAL = '';
    this.CustomerPortalState.forEach(CustomState => {
      if (d == CustomState.name) {
        VAL = CustomState.value;
      }
    }
    );
    return VAL;
  }
  /**
   * @description : this method will populate name of key from type of verification.
   * @param : any
   * @returns : Name
   * @author : Priyanka Pathak
   */

  GetDistrict(d: any) {
    let VAL = '';
    this.CustomerPortalDistrict.forEach(CustomerDistrict => {
      if (d == CustomerDistrict.name) {
        VAL = CustomerDistrict.value;
      }
    }
    );
    return VAL;
  }
  /**
  * @description : this method will populate name of key from type of verification.
  * @param : any
  * @returns : Name
  * @author : Priyanka Pathak
  */
  GetBgvStatus(d: any) {
    let VAL = '';
    this.BgvStatus.forEach(BgvStatus => {
      if (d == BgvStatus.name) {
        VAL = BgvStatus.value;
      }
    }
    );
    return VAL;
  }

}

//For Query Details Dialog 
@Component({
  templateUrl: './query-detail-dialog.html',
})
export class QueryDetailDialogComponent {


  //for Query details popup  
  id: string = "";
  name: string = "";
  date_entered: string = "";
  description: string = "";
  query_response: string = "";

  //for query reply
  queryReply: FormGroup;
  queryReplySubmit = false;

  constructor(
    private CasesService: CasesService,
    private router: Router,
    private formBuilder: FormBuilder

  ) { }


  ngOnInit() {
    //Query Reply Form validation 
    this.queryReply = this.formBuilder.group({
      reply: ['', Validators.required]
    });

    let queryId = this.CasesService.fetchQueryId();

    if (queryId) {
      this.CasesService.getQueryDetailByQueryId(queryId)
        .pipe(first())
        .subscribe(
          res => {
            if (JSON.stringify(res.status)) {
              this.id = JSON.stringify(res.data.query.id).split('"').join("");
              this.name = JSON.stringify(res.data.query.name).split('"').join("");
              this.date_entered = JSON.stringify(res.data.query.date_entered).split('"').join("");
              this.description = JSON.stringify(res.data.query.description).split('"').join("");
              this.query_response = JSON.stringify(res.data.query.query_response).split('"').join("");

            }
          },
          err => {
            if (err.error.status == 'error') {
              console.log(JSON.stringify(err.error.message));

            }
          }
        );
    }

  }

  // convenience getter for easy access to form fields of queryReply form 
  get f() { return this.queryReply.controls; }

  //Query Reply  
  onQueryReply() {
    this.queryReplySubmit = true;
    // stop here if no response entered
    if (this.queryReply.invalid) {
      return;
    }



    let queryId = this.CasesService.fetchQueryId();
    this.CasesService.queryReply(queryId, this.f.reply.value)
      .pipe(first())
      .subscribe(
        res => {
          window.location.reload();

        },
        err => {
          if (err.error.status == 'error') {
            console.log(JSON.stringify(err.error.message));
            // return to login
            this.router.navigate(['']);
          }
        }
      );
  }


}

//For Holiday Details Dialog 
@Component({
  templateUrl: './holiday-detail-dialog.html',
})
export class HolidayDetailDialogComponent {


  //for Query details popup  
  holidayData: any;
  holidayDataCount: Number;

  constructor(
    private CasesService: CasesService,
    private router: Router
  ) { }


  ngOnInit() {

    let to_date = this.CasesService.fetchToDate();
    let state = this.CasesService.fetchState();
    let due_day = this.CasesService.fetchDueDays();
    let BgvReportDate = this.CasesService.fetchBgvReport();
    let BgvDueDate = this.CasesService.fetchBgvDueDate();

    if (to_date && state && due_day) {
      this.CasesService.getHolidayDetailByQueryId(to_date, due_day, state,BgvReportDate, BgvDueDate)
        .pipe(first())
        .subscribe(
          res => {
            if (JSON.stringify(res.status)) {

              this.holidayData = res.data.result;
              this.holidayDataCount = res.data.result.length;
            }
          },
          err => {
            if (err.error.status == 'error') {
              console.log(JSON.stringify(err.error.message));
            }
          }
        );
    }

  }

}
