import { Injectable } from '@angular/core';
import { HttpClient, HttpClientModule, HttpHeaders } from '@angular/common/http';
import MyConstant from "../../config/my-constant";
import { map } from 'rxjs/operators';

@Injectable()
export class CasesService {
  saveCaseSuccessDatafalse(CaseSuccessDatafasle: any[]) {
    throw new Error("Method not implemented.");
  }

    private currentUser: any;
    private hitUrl: string;

    constructor(private http: HttpClient) {
        this.hitUrl = MyConstant.baseUrl;
    }

    private caseNumber: string = '';
    private cases: any[] = [];
    private queryId: string = '';
    private to_date: string = '';
    private due_day: string = '';
    private state: string = '';
    private defaultDate: string = '';
    private DFD: any;
    private DTD: any;
    private bgvReportDate: any;
    private bgvduedate_c: any;

    saveDashboardFromDate(DFD) {
        this.DFD = DFD;

    }
    fetchDashboardFromDate() {
        return this.DFD;
    }

    saveDashboardToDate(DTD) {
        this.DTD = DTD;

    }
    fetchDashboardToDate() {
        return this.DTD;
    }

    saveCaseNumber(caseNumber) {
        this.caseNumber = caseNumber;

    }
    fetchCaseNumber() {
        return this.caseNumber;
    }

    saveDashboardDate(defaultDate) {
        this.defaultDate = defaultDate;

    }
    fetchDashboardDate() {
        return this.defaultDate;
    }
    saveCaseSuccessData(cases) {
        this.cases = cases;

    }

    fetchCaseSuccessData() {
        return this.cases;
    }

    saveQueryId(queryId) {
        this.queryId = queryId;
    }

    fetchQueryId() {
        return this.queryId;
    }

    saveToDate(to_date) {
        this.to_date = to_date;
    }
    fetchToDate() {
        return this.to_date;
    }

    fetchDueDays() {
        return this.due_day;
    }

    saveDueDays(dueDays) {
        this.due_day = dueDays;
    }

    saveState(state) {
        this.state = state;
    }
    fetchState() {
        return this.state;
    }

    saveBgvReport(bgvReportDate) {
        this.bgvReportDate = bgvReportDate;
    }
    fetchBgvReport() {
        return this.bgvReportDate;
    }

    saveBgvDueDate(bgvduedate_c) {
        this.bgvduedate_c = bgvduedate_c;
    }
    fetchBgvDueDate() {
        return this.bgvduedate_c;
    }








    /**
     * @description : this method calls "base_url+case/list" if success then return error otherwise send error.
     * @param :
     * @returns : cases data in json format 
     * @author : Priyanka Pathak 
     */

    CasesData(offset: number, caseType: string, caseStatus: string, search: any, caseAgingStart: any, caseAgingEnd: any, caseSortColumn: string, caseSortOrder: string, param_tab: string, to_date: string, from_date: string, dashboardDays: Number) {

        this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
        const options = {
            headers: new HttpHeaders({
                "Content-Type": MyConstant.contentType,
                Authorization: this.currentUser.user.token
            })
        };

        //console.log("api calling cases: " + caseSortColumn + "end " + caseSortOrder);
        return this.http.post<any>(this.hitUrl + `case/list`, { offset: offset, verification_type: caseType, case_status: caseStatus, search: search, caseAgingStart: caseAgingStart, caseAgingEnd: caseAgingEnd, caseSortColumn: caseSortColumn, caseSortOrder: caseSortOrder, customer_portal_status: param_tab, from_date: from_date, to_date: to_date, dashboardDays: dashboardDays }, options)
            .pipe(map(cases => {
                return cases;
            }));
    }


    getCaseByNumber(case_number: string) {
        this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
        const options = {
            headers: new HttpHeaders({
                "Content-Type": MyConstant.contentType,
                Authorization: this.currentUser.user.token
            })
        };
        return this.http.post<any>(this.hitUrl + `case/get`, { caseNumber: case_number }, options)
            .pipe(map(data => {
                return data;
            }));
    }

    /**
    * @description : This method calls "base_url+/case/query/list" if success then return details of all query based on case number otherwise send no result.
    * @param : case_number
    * @returns : Query details in json format
    * @author : Roli Jain
    */

    getQueryListsByCaseByNumber(case_number: string) {
        this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
        const options = {
            headers: new HttpHeaders({
                "Content-Type": MyConstant.contentType,
                Authorization: this.currentUser.user.token
            })
        };
        return this.http.post<any>(this.hitUrl + `case/query/list`, { caseNumber: case_number }, options)
            .pipe(map(data => {
                return data;
            }));
    }
    /**
    * @description : This method calls "base_url+/case/getQuery" if success then return details of query based on query id  otherwise send error.
    * @param : Query Id
    * @returns : Query details in json format
    * @author : Roli Jain
    */
    getQueryDetailByQueryId(query_id: string) {
        this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
        const options = {
            headers: new HttpHeaders({
                "Content-Type": MyConstant.contentType,
                Authorization: this.currentUser.user.token
            })
        };
        return this.http.post<any>(this.hitUrl + `case/getQuery`, { queryId: query_id }, options)
            .pipe(map(data => {
                return data;
            }));
    }

    /**
    * @description : This method calls "base_url+/case/getQuery" if success then return details of query based on query id  otherwise send error.
    * @param : Query Id
    * @returns : Query details in json format
    * @author : Roli Jain
    */
    getHolidayDetailByQueryId(to_date: string, due_day: string, state: string, BgvReportDate: string, BgvDueDate: string) {
        this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
        const options = {
            headers: new HttpHeaders({
                "Content-Type": MyConstant.contentType,
                Authorization: this.currentUser.user.token
            })
        };
        return this.http.post<any>(this.hitUrl + `case/get/holiday`, { to_date: to_date, due_day: due_day, state: state, BgvReportDate: BgvReportDate, BgvDueDate: BgvDueDate }, options)
            .pipe(map(data => {
                return data;
            }));
    }

    /**    
    * @description : this method calls http://localhost:3000/client/1.0/user/authenticate if success then return error otherwise send error.
    * @param : otp, email 
    * @returns : user data in json format
    * @author : Roli Jain <roli.jain@icorprated.com>
    */

    queryReply(queryId: string, reply: string) {
        //console.log('queryReply calling');
        this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
        const options = {
            headers: new HttpHeaders({
                "Content-Type": MyConstant.contentType,
                Authorization: this.currentUser.user.token
            })
        };
        return this.http.post<any>(this.hitUrl + `case/query/reply`, { queryId: queryId, queryResponse: reply }, options)
            .pipe(map(response => {
                return response;
            }));

    }
    /**    
   * @description : this method calls http://localhost:3000/client/1.0/case/export if success then return excel file error otherwise send error.
   * @param :  offset
   * @returns : excel file 
   * @author : Priyanak Pathak
   */

    exportCases(offset: number, caseType: string, caseStatus: string, search: any, caseAgingStart: number, caseAgingEnd: number, caseSortColumn: string, caseSortOrder: string, param_tab: string, to_date: string, from_date: string, dashboardDays: string) {
        this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
        const options = {
            headers: new HttpHeaders({
                "Content-Type": MyConstant.contentType,
                Authorization: this.currentUser.user.token
            })
        };

        return this.http.post<any>(this.hitUrl + `case/export`, { offset: offset, verification_type: caseType, case_status: caseStatus, search: search, caseAgingStart: caseAgingStart, caseAgingEnd: caseAgingEnd, caseSortColumn: caseSortColumn, caseSortOrder: caseSortOrder, customer_portal_status: param_tab, from_date: from_date, to_date: to_date, dashboardDays: dashboardDays }, options)
            .pipe(map(response => {
                return response;
            }));
    }
    duczip(importCases) {
        this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
        const options = {
            headers: new HttpHeaders({
                "Content-Type": MyConstant.contentType,
                Authorization: this.currentUser.user.token
            })
        };

        return this.http.post<any>(this.hitUrl + `case/import`, {importCases}, options)
            .pipe(map(response => {
                // console.log(response);
                
                return response;
            }));
    }



}