import { Component, OnInit } from '@angular/core';
import { SideBarService } from '../sidebar-service';
import MyContstant from "../../config/my-constant";
import { Router } from '@angular/router';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  currentUser: any;
  accountName: string;
  linkActive: string;
   subscription;


  sidebarOpen: any = MyContstant.sidebar_collapsed;
  showSmallSideBar: any = MyContstant.sidebar_collapsed;
  menucontents: any = [
    { icon: "fab fa-android", title: "Dashboard" },
  ];

  constructor(private sideBarService: SideBarService, private router: Router) { }

  ngOnInit() {

    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.accountName = this.currentUser.user.account_name;

    let stringToSplit = this.router.url;
    let x = stringToSplit.split("/");

    this.linkActive = x[1];


    this.subscription = this.sideBarService.change.subscribe((isOpen: any) => {
      this.sidebarOpen = isOpen;
      setTimeout(() => {
        this.showSmallSideBar = isOpen;
      }, 300);
    });
  }

  windowLoad(){
    //location.reload();
  }

  ngOnDestroy() {
  this.sideBarService.reset();
  MyContstant.sidebar_collapsed = true;
  this.sidebarOpen = MyContstant.sidebar_collapsed;
  this.showSmallSideBar = MyContstant.sidebar_collapsed;
  this.subscription.unsubscribe();
  }
}
