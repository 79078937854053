import { Component, OnInit } from '@angular/core';

import { DashboardService } from '../services/dashboard.service';
import { Router } from '@angular/router';
import { jsonpCallbackContext } from '@angular/common/http/src/module';
import * as moment from 'moment';
import { first } from 'rxjs/operators';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import { MultiDataSet, Label } from 'ng2-charts';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import { CasesService } from '../services/cases.service';
import { MasterDataService } from "../services/master-data.service";

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"]
})
export class DashboardComponent implements OnInit {
  public doughnutChartLabels: Label[];
  public doughnutChartType: ChartType;
  private options: any;

  public barChartOptions: ChartOptions = {
    responsive: true,
    // We use these empty structures as placeholders for dynamic theming.
    scales: {
      xAxes: [
        {
          gridLines: {
            display: false
          }
        }
      ],
      yAxes: [
        {
          gridLines: {
            display: false
          },
          ticks: { min: 0 }
        }
      ]
    },
    plugins: {
      datalabels: {
        anchor: "end",
        align: "end"
      }
    }
  };

  public barChartLabels: Label[];
  public barChartType: ChartType = "bar";
  public barChartLegend = true;
  public barChartPlugins = [pluginDataLabels];
  public barChartData: ChartDataSets[];
  showLoader = true;
  color = "#5cc6d0";
  mode = "indeterminate";
  value = 50;

  datePicker: boolean = false;

  currentUser: any;
  account_id: string;
  total_alloted_cases_count: Number;
  open_cases_count: string;
  case_in_progress_count: string;
  cases_completed_count: string;
  stop_check_count: string;
  unreplied_query_count: string;
  query_raised_count: string;
  qa_pending_count: string;
  tat_percent_count: Number = 0;
  dataSource: Object;
  sampleData: any;
  padding: any;
  titlePadding: any;
  xAxis: any;
  valueAxis: any;
  seriesGroups: any[];
  data: any;
  source: any;
  dataAdapter: any;
  paddingChart: any;
  titlePaddingChart: any;
  seriesGroupsChart: any[];
  pieheight: any;
  piewidth: any;
  chartwidth: any;
  chartheight: any;
  to_date: any;
  from_date: any;
  selected;
  label1: any;
  label2: any;
  label3: any;
  label4: any;
  label5: any;

  data_source_mobile: any;
  dataAdapter_mobile: any;
  data_source_desktop: any;
  dataAdapter_desktop: any;
  legendLayout: any;
  getWidth: any;

  datasets: any[];
  defaultDate: any = "";
  minDate: moment.Moment;

  constructor(
    private masterDataService: MasterDataService,
    private dashboardService: DashboardService,
    private router: Router,
    private CasesService: CasesService
  ) {
    this.masterDataService.getDropdown();
    this.minDate = moment().subtract(3, "month");
  }

  ngOnInit() {
    //empty default date and days of dashboard
    this.CasesService.saveDashboardToDate("");
    this.CasesService.saveDashboardFromDate("");
    this.CasesService.saveDashboardDate("");

    this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
    this.account_id = this.currentUser.user.account_id;
    this.to_date = "";
    this.from_date = "";
    this.GetDashboardCountData(this.account_id, this.to_date, this.from_date);
  }

  resetForm() {
    window.location.reload();
  }

  /**
   * @description : this method will populate total count on dashboard page.
   * @param : any
   * @returns : Name
   * @author : Nitesh Sethi
   */
  GetDashboardCountData(
    account_id: string,
    to_date: string,
    from_date: string
  ) {
    this.dashboardService
      .dashboard(account_id, "", to_date, from_date)
      .pipe(first())
      .subscribe(
        res => {
          if (JSON.stringify(res.status)) {
            //let TAC = parseInt(JSON.stringify(res.data.result.total_alloted_cases_count)) + parseInt(JSON.stringify(res.data.result.query_raised_count));
            this.total_alloted_cases_count =
              res.data.result.total_alloted_cases_count;
            this.open_cases_count = JSON.stringify(
              res.data.result.open_cases_count
            );
            this.case_in_progress_count = JSON.stringify(
              res.data.result.case_in_progress_count
            );
            this.cases_completed_count = JSON.stringify(
              res.data.result.cases_completed_count
            );
            this.stop_check_count = JSON.stringify(
              res.data.result.stop_check_count
            );
            this.unreplied_query_count = JSON.stringify(
              res.data.result.unreplied_query_count
            );
            this.query_raised_count = JSON.stringify(
              res.data.result.query_raised_count
            );
            this.qa_pending_count = JSON.stringify(
              res.data.result.qa_pending_count
            );
            this.GetPieData(
              this.open_cases_count,
              this.case_in_progress_count,
              this.cases_completed_count,
              this.stop_check_count,
              this.unreplied_query_count
            );
            this.showLoader = false;
          }
        },
        err => {
          if (err.error.status == "error") {
            console.log(
              "Error:" +
                JSON.stringify(err.error.message)
                  .split('"')
                  .join("")
            );
          }
          this.showLoader = false;
        }
      );
    this.dashboardService
      .dashboard_case_aging(this.account_id, to_date, from_date)
      .pipe(first())
      .subscribe(
        res => {
          if (JSON.stringify(res.status)) {
            if (res.data.result.length > 0) {
              this.label1 = 0;
              this.label2 = 0;
              this.label3 = 0;
              this.label4 = 0;
              this.label5 = 0;

              res.data.result.forEach(element => {
                if (element.label == "0-5") {
                  this.label5 = element.value;
                }
                if (element.label == "11-15") {
                  this.label3 = element.value;
                }
                if (element.label == "16-25") {
                  this.label2 = element.value;
                }
                if (element.label == "26-90") {
                  this.label1 = element.value;
                }
                if (element.label == "6-10") {
                  this.label4 = element.value;
                }
              });
              this.GetBarData(
                this.label1,
                this.label2,
                this.label3,
                this.label4,
                this.label5
              );
            } else {
              this.GetBarData(0, 0, 0, 0, 0);
            }
          }
        },
        err => {
          if (err.error.status == "error") {
            console.log(
              "Error:" +
                JSON.stringify(err.error.message)
                  .split('"')
                  .join("")
            );
          }
        }
      );
    this.dashboardService
      .tatCalculation(this.account_id, to_date, from_date)
      .pipe(first())
      .subscribe(
        res => {
          if (JSON.stringify(res.status)) {
            //console.log("TAT data "+JSON.stringify(res.data));
            this.tat_percent_count = res.data;
          }
        },
        err => {
          if (err.error.status == "error") {
            console.log(
              "Error:" +
                JSON.stringify(err.error.message)
                  .split('"')
                  .join("")
            );
          }
        }
      );
  }

  /**
   * @description : this method will populate pie chart on dashboard page.
   * @param : any
   * @returns : Name
   * @author : Nitesh Sethi
   */
  onResize(event) {
    alert(event.target.innerWidth);
  }
  GetPieData(
    openCaseTC: string,
    caseInProgressCaseTC: string,
    completedTC: string,
    stopCheckTC: string,
    unRepliedQueriesTC: string
  ) {
    this.doughnutChartLabels = [
      "Open",
      "In Progress",
      "Completed",
      "Stop Check",
      "Unreplied Queries"
    ];
    this.doughnutChartType = "doughnut";
    this.options = {
      legend: { position: "right" },
      plugins: {
        datalabels: {
          render: "value",
          color: "black",
          font: {
            size: 14
            //weight: 'bold'
          }
        }
      }
      // cutoutPercentage: 40
    };
    this.datasets = [
      {
        data: [
          openCaseTC,
          caseInProgressCaseTC,
          completedTC,
          stopCheckTC,
          unRepliedQueriesTC
        ],

        backgroundColor: [
          "#7e51fc",
          "#b970fe",
          "#3adaa0",
          "#ea4c4c",
          "#5cc6d0"
        ],
        hoverBackgroundColor: [
          "#761efa",
          "#9a57ff",
          "#24bdba",
          "#ea4c4c",
          "#5cc6d0"
        ]
      }
    ];
  }

  /**
   * @description : this method will populate bar graph on dashboard page.
   * @param : any
   * @returns : Name
   * @author : Nitesh Sethi
   */
  GetBarData(
    label1: number,
    label2: number,
    label3: number,
    label4: number,
    label5: number
  ) {
    this.barChartLabels = ["0-5", "6-10", "11-15", "16-25", "26-90"];
    this.barChartData = [
      {
        data: [label5, label4, label3, label2, label1],
        label: "Case Aging",
        backgroundColor: "rgb(126, 81, 252)",
        hoverBackgroundColor: "rgb(118, 30, 250)"
      }
    ];
  }
  GetBarData12(
    label1: string,
    label2: string,
    label3: string,
    label4: string,
    label5: string
  ) {
    this.sampleData = [
      { label: "21-99", value: label1 },
      { label: "16-20", value: label2 },
      { label: "11-15", value: label3 },
      { label: "6-10", value: label4 },
      { label: "1-5", value: label5 }
    ];

    this.padding = { left: 0, top: 0, right: 0, bottom: 0 };

    this.titlePadding = { left: 90, top: 0, right: 0, bottom: 10 };
    this.chartwidth = "100%";
    this.chartheight = "300";
    this.xAxis = {
      dataField: "label",
      gridLines: { visible: false },
      flip: true
    };

    this.valueAxis = {
      flip: false,
      gridLines: { visible: false },
      labels: {
        visible: true,
        formatFunction: (value: string) => {
          return parseInt(value);
        }
      }
    };

    this.seriesGroups = [
      {
        type: "column",
        orientation: "vertically",
        columnsGapPercent: 50,
        toolTipFormatSettings: { thousandsSeparator: "," },
        series: [{ dataField: "value", displayText: "" }]
      }
    ];
  }

  /**
   * @description : this method will populate total count on dashboard page with selected date's range.
   * @param : any
   * @returns : Name
   * @author : Nitesh Sethi
   */
  change(event) {
    this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
    this.account_id = this.currentUser.user.account_id;
    let frm_dt = moment(this.selected.end)
      .format()
      .split("T");
    let to_dt = moment(this.selected.start)
      .format()
      .split("T");

    this.to_date = to_dt[0];
    this.from_date = frm_dt[0];
    if (this.to_date != "Invalid date" && this.from_date != "Invalid date") {
      this.showLoader = true;
      this.GetDashboardCountData(this.account_id, this.to_date, this.from_date);
    }
  }

  onClickMe() {
    if (this.to_date != "Invalid date" && this.from_date != "Invalid date") {
      this.CasesService.saveDashboardFromDate(this.from_date);
      this.CasesService.saveDashboardToDate(this.to_date);
    } else {
      this.defaultDate = "90";
      this.CasesService.saveDashboardDate(this.defaultDate);
    }
  }
}
